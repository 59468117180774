import * as yup from 'yup';
import { api } from '@ranch/api';
import { settings } from '@ranch/config';

const programsSchema = yup.object().shape({
  title: yup.string().required('Escriba el título.'),
  schedule: yup.string().required('Escriba la descripción.'),
  order_shown: yup.string().required('Escriba el orden de aparición.'),
});

const getProgram = programId => {
  const url = `/programs/${programId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const getPrograms = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/programs', { params: endpointProps })
    .then(({ status, data }) => {
      const { programs } = data;
      const totalPages = programs?.total || 0;
      return [null, status, { programs: programs?.data || [] }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const createUpdateProgram = (program, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${program.id}`;
  const url = `/programs${params}`;

  return api[action](url, program)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const deleteProgram = id => api
.delete('/programs/'+id)
.then(({ status, data }) => [null, status, data])
.catch(error => [error, null, null]);

export { programsSchema, getPrograms, getProgram, createUpdateProgram, deleteProgram };
