import React, { useEffect, useReducer } from "react";
import { useTheme } from "styled-components";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  PageHeader,
  Dropdown,
  TextInput,
  Divider,
  Button,
  Column,
  Error,
} from "@ranch/components";
import { getModules } from "@ranch/models/modules";
import {
  profileSchema,
  createUpdateProfile,
  getProfile,
} from "@ranch/models/profiles";
import { notify } from "react-notify-toast";
import { routes } from "@ranch/config";

const ProfileDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    modules: [],
    isLoadingModules: false,
    selectedModules: [],
    saving: false,
  });

  const { isLoadingModules, modules, selectedModules, saving } = state;

  const { register, handleSubmit, errors, setValue, getValues, watch } =
    useForm({
      resolver: yupResolver(profileSchema),
    });

  const modulesWatcher = watch("modules");

  const hasError = (field) => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onSubmit = async (data) => {
    setState({ saving: true });
    const submitType = id && id > 0 ? "update" : "create";
    const formattedModules = data.modules.map((item) => item.value);

    const profileData = {
      title: data.title,
      modules: formattedModules,
    };

    if (id && id > 0) {
      profileData.id = id;
    }

    const [error, status, { message }] = await createUpdateProfile(
      profileData,
      submitType
    );

    if (status === 200) {
      if (message === "UPDATED_PROFILE") {
        notify.show("Perfíl actualizado correctamente.", "success");
      }

      if (message === "CREATED_PROFILE") {
        notify.show("Perfíl creado correctamente.", "success");
        setTimeout(() => {
          history.push(routes.PROFILES);
        }, 1500);
      }
    }

    if (status === 202) {
      if (message === "ERROR_UPDATING_PROFILE") {
        notify.show("Error al actualizar perfíl.", "error");
      }

      if (message === "ERROR_CREATING_PROFILE") {
        notify.show("Error al crear perfíl.", "error");
      }
    }

    setState({ saving: false });
  };

  const fetchModules = async (id) => {
    register({ name: "modules", value: [] });
    setState({ isLoadingModules: true });
    const [error, status, modules] = await getModules();
    setState({ isLoadingModules: false, modules });

    if (id && Number(id)) {
      const [errorProfile, statusProfile, profile] = await getProfile(id);
      if (statusProfile === 200 && profile && modules) {
        setValue("title", profile.title);
        const selectedModulesIds = profile.modules.map(
          (item) => item.module_id
        );

        const findSelectedModules = [];
        modules.forEach((item) => {
          if (selectedModulesIds.includes(item.value)) {
            findSelectedModules.push(item);
          }
        });
        setValue("modules", findSelectedModules);
      }
    }
  };

  useEffect(() => {
    fetchModules(id);
  }, [id]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"}>
          <PageHeader>Detalles del perfíl</PageHeader>
        </Container>
        <Column padding={"15px"} flex={0}>
          <TextInput
            name={"title"}
            label={"Título"}
            ref={register}
            type={"text"}
            error={hasError("title")}
          />
          <Divider size={20} />
          <Dropdown
            title={"Permisos"}
            options={modules}
            name={"modules"}
            loading={isLoadingModules}
            isMulti
            value={!isLoadingModules && getValues("modules")}
            onChange={(e) => {
              setValue("modules", e);
            }}
            style={{ flex: 0 }}
          />
          {hasError("modules") && <Error>{hasError("modules")}</Error>}
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Perfíl
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default ProfileDetails;
