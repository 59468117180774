import styled, { css } from 'styled-components';

const setColor = (alertType) => {
    switch(alertType){
        case 'grey':
    return css`
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
    `

    case 'success':
        return css`
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        `

        case 'error':
        return css`
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        `

        case 'warning':
        return css`
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        `
        
        default:
        case 'info':
            return css`
                color: #004085;
                background-color: #cce5ff;
                border-color: #b8daff;
            `
        
    }
}

const Wrapper = styled.div`
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    ${(props) => props.type && setColor(props.type)};
`;

export {
    Wrapper
}