import styled from 'styled-components';

const Container = styled.div`
  transition: all 300ms ease;
  display: none;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;

  ${({ visibility }) =>
    visibility &&
    `
    display:flex !important;
  `};
`;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
`;

const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  border-top: 1px solid #f1f1f1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export { Backdrop, Modal, Body, Header, Footer, Container };
