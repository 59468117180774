import React from 'react';
import { Container } from './styles';

const ActivityIndicator = () => {
  return (
    <Container>
      <div className="lds-dual-ring"></div>
    </Container>
  );
};

export default ActivityIndicator;
