import { api } from "@ranch/api";
import * as yup from "yup";

const sourceSchema = yup.object().shape({
  title: yup.string().required("Escriba el título de la fuente."),
  description: yup.string(),
  web_site: yup.string(),
  email: yup.string().required("Escriba el email de la fuente."),
  type: yup.mixed().oneOf(["interna", "externa"]),
  file_id: yup.number().required("Selecciona la imagen de la fuente."),
  observations: yup.string(),
});

const formatSources = (items) =>
  items.map((item) => {
    return {
      label: item.title,
      value: item.id,
    };
  });

const getSources = (returnSourcesFormatted) => {
  return api
    .get("/source", {
      params: {
        limit: 999999,
      },
    })
    .then(({ status, data }) => {
      let sources = data;
      const totalPages = data.totalItems || 0;
      if (returnSourcesFormatted) {
        sources = { sources: formatSources(data.sources) };
      }
      return [null, status, sources, totalPages];
    })
    .catch((error) => {
      return [error, null, null];
    });
};

const _getSources = (page = 1) => {
  return api
    .get("/source", {
      params: {
        page,
      },
    })
    .then(({ status, data }) => {
      let sources = data;
      const totalPages = data.totalItems || 0;
      return [null, status, sources, totalPages];
    })
    .catch((error) => {
      return [error, null, null];
    });
};

const createUpdateSource = (source, actionType = "create") => {
  const action = actionType === "create" ? "post" : "put";
  const params = actionType === "create" ? "" : `/${source.id}`;
  const url = `/source${params}`;

  return api[action](url, source)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const getSource = (sourceId) => {
  const url = `/source/${sourceId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const deleteSource = (sourceId) =>
  api
    .delete("/source/" + sourceId)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

export {
  getSources,
  sourceSchema,
  createUpdateSource,
  getSource,
  deleteSource,
  _getSources,
};
