import React, { useReducer, useEffect } from "react";
import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Dropdown,
  TextInput,
  Divider,
  Button,
  Icon,
} from "@ranch/components";
import {
  getMultimedia,
  deleteMultimedia,
  multimediaFilterSchema,
} from "@ranch/models/multimedia";
import { routes } from "@ranch/config";
import useQueryParams from "@ranch/hooks/useQueryParams";
import { notify } from "react-notify-toast";
import { sendMultimediaNotification } from "@ranch/models/notifications";

const DEFAULT_QUERY_VALUES = {
  page: 0,
  keyword: "",
};

const MultimediaScreen = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    items: [],
    error: null,
    totalPages: 0,
    inputTitle: null,
  });

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page, keyword } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const { register, handleSubmit, errors, setValue, getValues, reset } =
    useForm({
      resolver: yupResolver(multimediaFilterSchema),
    });

  const { loading, items, totalPages } = state;

  const onSubmit = (data) => {
    const filters = {};

    if (data.title) {
      filters.keyword = data.title;
    } else {
      filters.keyword = "";
    }

    filters.page = 1;

    setQueryParam(filters);
  };

  const fetchMultimedia = async (updatedPage = 0) => {
    setState({ loading: true });
    const currentPage = updatedPage || page;

    const [error, status, { multimedia }, totalPages] = await getMultimedia(
      currentPage,
      keyword
    );

    if (error) setState({ error });
    if (status !== 200) setState({ error, items: multimedia });
    if (status === 200 && multimedia && multimedia.length > 0 && !error) {
      setState({ items: multimedia, error: null });
    }
    if (status === 200 && multimedia && multimedia.length === 0 && !error) {
      setState({ items: [], error: null });
    }
    setState({ loading: false, totalPages });
  };

  useEffect(() => {
    fetchMultimedia(page);
  }, [page, keyword]);

  const handleDeleteItem = async (itemId) => {
    if (itemId) {
      const confirm = window.confirm(
        "¿Deseas eliminar permanentement este elemento?"
      );

      if (confirm) {
        const [error, status, data] = await deleteMultimedia(itemId);
        if (error || status !== 200) {
          notify.show("Error al borrar.", "error");
        } else {
          notify.show("Elemento borrado correctamente", "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }
  };

  const handleSendNotification = async (itemId) => {
    const confirm = window.confirm(
      "¿Deseas enviar una notificacion para todos los usuarios de la app?"
    );

    if (confirm) {
      const [error, status, data] = await sendMultimediaNotification(itemId);
      if (error || status !== 200 || data.message === "NOTIFICATION_NOT_SENT") {
        notify.show("Error al enviar notificacion", "error");
      } else {
        notify.show("Notificacion enviada correctamente", "success");
      }
    }
  };

  const renderActions = (cellData) => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={"transparent"}
        row
        justify={"flex-start"}
        padding={"0px 15px 0 0"}
      >
        <div onClick={() => history.push(routes.MULTIMEDIA_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        <Divider />
        <div onClick={() => handleDeleteItem(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const newsTableColumns = [
    {
      Header: "Título",
      accessor: "title",
      width: 200,
      Cell: ({ value, cell }) => (
        <div
          onClick={() =>
            history.push(routes.MULTIMEDIA_ITEM(cell.row.original.id))
          }
        >
          <span>{value}</span>
        </div>
      ),
    },
    {
      Header: "Fecha de creación",
      accessor: "createdAt",
      width: 200,
    },
    {
      Header: "Acciones",
      accessor: "",
      width: 150,
      Cell: (cell) => renderActions(cell),
    },
  ];

  const Filters = () => {
    return (
      <Container>
        <h4>Búsqueda</h4>
        <Container row justify={"flex-start"}>
          <div>
            <span>Título</span>
            <br />
            <TextInput
              placeholder="Buscar por título"
              ref={register}
              name={"title"}
              defaultValue={getValues("title")}
            />
          </div>
          <Divider />
          <div>
            <Divider size={20} />
            <Button
              color={theme.colors.primary}
              width={"40px"}
              onClick={handleSubmit(onSubmit)}
            >
              <Icon name={"search"} style={{ fontSize: 14 }} />
            </Button>
          </div>
          <Divider />
          <div>
            <Divider size={20} />
            <Button
              color={theme.colors.primary}
              width={"40px"}
              onClick={() => {
                reset();
                setQueryParam(DEFAULT_QUERY_VALUES);
              }}
            >
              <Icon name={"clear"} style={{ fontSize: 14 }} />
            </Button>
          </div>
        </Container>
      </Container>
    );
  };

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container row flex={0} padding={"15px"} justify={"space-between"}>
          <PageHeader>Multimedia</PageHeader>
          <Button
            width={"160px"}
            onClick={() => history.push(routes.MULTIMEDIA_ITEM("new"))}
          >
            Crear Nuevo
          </Button>
        </Container>
        <Container padding={"15px"}>
          <Filters />
        </Container>
        <Container padding={"15px"}>
          <Table columns={newsTableColumns} data={items} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={(page) => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default MultimediaScreen;
