import styled from 'styled-components';

const Divider = styled.div`
  width: 20px;
  height: 40px;

  ${({ size }) =>
    size &&
    `
        width:${size}px;
        height:${size}px;
    `};
`;

export default Divider;
