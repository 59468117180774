import axios from "axios";
import { store, settings } from "@ranch/config";

const { isLocal, DEV_ENDPOINT, PROD_ENDPOINT } = settings;

const ApiClient = axios.create({
  responseType: "json",
  baseURL: isLocal() ? DEV_ENDPOINT : PROD_ENDPOINT,
});

ApiClient.defaults.headers.post["Content-Type"] = "application/json";

// 30 second timeout for socket connection
// note, might not work on all platforms https://github.com/axios/axios/issues/647
ApiClient.defaults.timeout = 300000; //5 minutes

ApiClient.interceptors.request.use(
  async (config) => {
    const authToken = store.getItem("authToken");

    config.headers["Content-Type"] = "application/json";
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default ApiClient;
