import React, { useState, useEffect } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Text, Column } from "@ranch/components";

const TagsInput = ({
  tags = [],
  label = "",
  labelStyle = {},
  onChange,
  placeholder = "",
  shouldSanitize = false,
}) => {
  const [currentTags, setTags] = useState(tags);

  useEffect(() => {
    setTags(tags);
  }, [tags]);

  return (
    <Column>
      <Text style={labelStyle}>{label}</Text>
      <ReactTagInput
        tags={currentTags}
        onChange={(tags) => {
          if (shouldSanitize) {
            const sanitizedTags = tags.map((item) =>
              item.replace(/[^a-zA-Z0-9 ]/g, "")
            );
            const _sanitizedTags = sanitizedTags.map((item) =>
              item.replaceAll(" ", "")
            );
            const lowerCaseTags = _sanitizedTags.map((item) =>
              item.toLowerCase()
            );
            setTags(lowerCaseTags);
            onChange(lowerCaseTags);
          } else {
            setTags(tags);
            onChange(tags);
          }
        }}
        placeholder={placeholder || 'Escriba y presione "Enter".'}
      />
    </Column>
  );
};

export default TagsInput;
