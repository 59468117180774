import React, { useReducer, useEffect, useCallback } from "react";
import moment from "moment";
import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import {
  Container,
  Table as NewTable,
  PageHeader,
  Pagination,
  Dropdown,
  TextInput,
  Divider,
  Button,
  Icon,
  ImageBackground,
  ActivityIndicator,
  Text,
  Alert,
} from "@ranch/components";
import { getNews, updateSuperFeaturedPositions } from "@ranch/models/news";
import { getCategories } from "@ranch/models/categories";
import { settings, routes } from "@ranch/config";
import useQueryParams from "@ranch/hooks/useQueryParams";
import { newsFiltersSchema, deleteNote } from "@ranch/models/news";
import { sendNewsNotification } from "@ranch/models/notifications";
import { notify } from "react-notify-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TableStyles,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
  Table,
} from "../components/common/Table/styles";

const DEFAULT_QUERY_VALUES = {
  page: 0,
  keyword: "",
  orderBy: "created_at",
  published: -1,
  category_id: 0,
  createdAt: null,
  inLastNews: -1,
  superFeatured: -1,
};

const NewsScreen = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    news: [],
    error: null,
    totalPages: 0,
    categoryItems: [],
    isLoadingCategories: false,
    inputCreatedAt: null,
    inputPublished: null,
    inputCategory: null,
    inputTitle: null,
    error: null,
  });

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const {
    page,
    keyword,
    orderBy,
    category_id,
    published,
    createdAt,
    inLastNews,
    superFeatured,
  } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const { register, handleSubmit, setValue, getValues, watch, reset } =
    useForm({
      resolver: yupResolver(newsFiltersSchema),
    });

  watch("published", 0);
  watch("categoryId", 0);
  watch("inLastNews", 0);
  watch("superFeatured", 0);

  const {
    loading,
    news,
    totalPages,
    categoryItems,
    isLoadingCategories,
    error,
  } = state;

  const handleDrop = async (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...news];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    //Update news super featured position
    const newsList = [];
    updatedList.forEach((item, index) => {
      newsList.push({
        id: item.id,
        position: index + 1,
      });
    });

    const [error, status] = await updateSuperFeaturedPositions(newsList);

    if (!error && status == 200) {
      notify.show("Posiciones actualizadas", "success");
    }
    notify.show("Hubo un error al actualizar las posiciones", "error");

    // Update State
    setState({ news: updatedList });
  };

  const renderItems = () => {
    if (loading) {
      return <ActivityIndicator />;
    }

    if (error) {
      return <Text>Ocurrió un error</Text>;
    }

    return (
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <TableStyles>
              <Table
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <TableHeader>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Sección</TableCell>
                    <TableCell>Fecha de creación</TableCell>
                    <TableCell>Publicada</TableCell>
                    <TableCell>Posición</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {news.map((item, index) => (
                    <Draggable
                      key={`note-${item.id}`}
                      draggableId={`note-${item.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          className="item-container"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <TableCell width={100}>
                            <ImageBackground src={item.image} />
                          </TableCell>
                          <TableCell width={450}>
                            <Text
                              color={theme.colors.black}
                              size={15}
                            >{`${item.title}`}</Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              color={theme.colors.black}
                              size={15}
                            >{`${item.category}`}</Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              color={theme.colors.black}
                              size={15}
                            >{`${item.createdAt}`}</Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              color={theme.colors.black}
                              size={15}
                            >{`${item.isPublished}`}</Text>
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{renderActions(item)}</TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                </TableBody>
                {provided.placeholder}
              </Table>
            </TableStyles>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const onSubmit = (data) => {
    const filters = {};

    if (data.title) {
      filters.keyword = data.title;
    } else {
      filters.keyword = "";
    }

    if (data.createdAt) {
      filters.createdAt = moment(data.createdAt).format("Y-MM-DD");
    } else {
      filters.createdAt = null;
    }

    if (data.orderBy) {
      filters.orderBy = data.orderBy;
    }

    if (data.categoryId) {
      filters.category_id = data.categoryId;
    }

    if (data.inLastNews > -1) {
      filters.inLastNews = data.inLastNews;
    } else {
      filters.inLastNews = -1;
    }

    if (data.published > -1) {
      filters.published = data.published;
    } else {
      filters.published = -1;
    }

    if (data.superFeatured > -1) {
      filters.superFeatured = data.superFeatured;
    } else {
      filters.superFeatured = -1;
    }

    filters.page = 1;

    setQueryParam(filters);
  };

  const fetchNews = useCallback(async (updatedPage = 0) => {
    setState({ loading: true });
    const currentPage = updatedPage || page;
    const ordering = superFeatured === 1 ? "super_featured_position" : orderBy;

    const [error, status, { news }, totalPages] = await getNews(
      currentPage,
      keyword,
      category_id,
      ordering,
      published,
      createdAt,
      inLastNews,
      superFeatured
    );

    if (error) setState({ error });
    if (status !== 200) setState({ error, news });
    if (status === 200 && news && news.length > 0 && !error) {
      const orderedNews =
        superFeatured === 1
          ? news.sort((a, b) => {
              if (a.super_featured_position > b.super_featured_position)
                return 1;
              if (a.super_featured_position < b.super_featured_position)
                return -1;
              return 0;
            })
          : news;

      setState({ news: orderedNews, error: null });
    }
    if (status === 200 && news && news.length === 0 && !error) {
      setState({ news: [], error: null });
    }
    setState({ loading: false, totalPages });
  },[category_id, createdAt, inLastNews, keyword, orderBy, page, published, superFeatured])

  useEffect(() => {
    fetchNews(page);
  }, [page, keyword, orderBy, category_id, published, createdAt, inLastNews, superFeatured, fetchNews]);

  const handleSendNotification = async (newsId) => {
    if (newsId) {
      const confirm = window.confirm(
        "¿Deseas enviar una notificacion para todos los usuarios de la app?"
      );

      if (confirm) {
        const [error, status, data] = await sendNewsNotification(newsId);
        if (
          error ||
          status !== 200 ||
          data.message === "NOTIFICATION_NOT_SENT"
        ) {
          notify.show("Error al enviar notificacion", "error");
        } else {
          notify.show("Notificacion enviada correctamente", "success");
        }
      }
    }
  };

  const handleDeleteNote = async (newsId) => {
    if (newsId) {
      const confirm = window.confirm(
        "¿Deseas eliminar permanentement esta nota?"
      );

      if (confirm) {
        const [error, status, data] = await deleteNote(newsId);
        if (error || status !== 200) {
          notify.show("Error al borrar la nota", "error");
        } else {
          notify.show("Nota borrada correctamente", "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }
  };

  const preloadFilters = useCallback(async () => {
    register({ name: "published" });
    register({ name: "categoryId" });
    register({ name: "inLastNews" });
    register({ name: "superFeatured" });
    setState({ isLoadingCategories: true });
    const returnCategoriesFormatted = true;
    const [categoriesError, categoriesStatus, { categories }] =
      await getCategories(returnCategoriesFormatted);
    if (categoriesError) {
    }
    if (categoriesStatus !== 200) {
    }
    if (
      categoriesStatus === 200 &&
      categories &&
      categories.length > 0 &&
      !categoriesError
    ) {
      setState({ categoryItems: categories, isLoadingCategories: false });
    }
    setState({ isLoadingCategories: false });
  },[register]);

  useEffect(() => {
    preloadFilters();
  }, [preloadFilters]);

  const renderActions = (cellData) => {
    const id =
      cellData && cellData.row ? cellData.row.original.id : cellData.id;

    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={"transparent"}
        row
        justify={"space-between"}
        padding={"0px 15px 0 0"}
      >
        <div onClick={() => history.push(routes.NEWS_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        {/*<div onClick={() => history.push(routes.CLONE_NEWS(id))}>
          <Icon name="callMade" style={iconStyle} />
        </div>*/}
        <div onClick={() => handleSendNotification(id)}>
          <Icon name="bell" style={iconStyle} />
        </div>
        <div onClick={() => handleDeleteNote(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const newsTableColumns = [
    {
      Header: "#",
      accessor: "image",
      width: 100,
      Cell: ({ value, cell }) => (
        <div
          onClick={() => history.push(routes.NEWS_ITEM(cell.row.original.id))}
        >
          <ImageBackground src={value} />
        </div>
      ),
    },
    {
      Header: "Título",
      accessor: "title",
      width: 400,
      Cell: ({ value, cell }) => (
        <div
          onClick={() => history.push(routes.NEWS_ITEM(cell.row.original.id))}
        >
          <span>{value}</span>
        </div>
      ),
    },
    {
      Header: "Sección",
      accessor: "category",
      width: 250,
    },
    {
      Header: "Fecha de creación",
      accessor: "createdAt",
      width: 200,
    },
    {
      Header: "Publicada",
      accessor: "isPublished",
    },
    {
      Header: "Acciones",
      accessor: "",
      width: 150,
      Cell: (cell) => renderActions(cell),
    },
  ];

  const Filters = () => {
    return (
      <Container>
        <h4>Búsqueda</h4>
        <Container row justify={"space-between"}>
          <div>
            <span>Título</span>
            <br />
            <TextInput
              placeholder="Buscar por título"
              ref={register}
              name={"title"}
              defaultValue={getValues("title")}
            />
          </div>
          <Divider />
          <div>
            <span>Fecha</span>
            <br />
            <TextInput type="date" ref={register} name={"createdAt"} />
          </div>
          <Divider />
          <Dropdown
            title={"Publicada"}
            options={settings.publishedValues}
            value={settings.publishedValues.find(
              (item) => item.value === getValues("published")
            )}
            name={"published"}
            onChange={(e) => setValue("published", e.value)}
          />
          <Divider />
          <Dropdown
            title={"Sección"}
            options={categoryItems}
            isLoading={isLoadingCategories}
            name={"categoryId"}
            value={
              !isLoadingCategories &&
              categoryItems.find(
                (item) => item.value === getValues("categoryId")
              )
            }
            onChange={(e) => setValue("categoryId", e.value)}
          />
          <Divider />
          <Dropdown
            title={"Ultimas noticias"}
            options={settings.publishedValues}
            value={settings.publishedValues.find(
              (item) => item.value === getValues("inLastNews")
            )}
            name={"inLastNews"}
            onChange={(e) => setValue("inLastNews", e.value)}
          />
          <Divider />
          <Dropdown
            title={"¿Es importante?"}
            options={settings.publishedValues}
            value={settings.publishedValues.find(
              (item) => item.value === getValues("superFeatured")
            )}
            name={"superFeatured"}
            onChange={(e) => setValue("superFeatured", e.value)}
          />
          <Divider />
          <div>
            <Divider size={20} />
            <Button
              color={theme.colors.primary}
              width={"40px"}
              onClick={handleSubmit(onSubmit)}
            >
              <Icon name={"search"} style={{ fontSize: 14 }} />
            </Button>
          </div>
          <Divider />
          <div>
            <Divider size={20} />
            <Button
              color={theme.colors.primary}
              width={"40px"}
              onClick={() => {
                reset();
                register({ name: "published" });
                register({ name: "categoryId" });
                register({ name: "inLastNews" });
                setQueryParam(DEFAULT_QUERY_VALUES);
              }}
            >
              <Icon name={"clear"} style={{ fontSize: 14 }} />
            </Button>
          </div>
        </Container>
      </Container>
    );
  };

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"}>
          <PageHeader>Noticias</PageHeader>
          <Filters />
          {superFeatured > 0 && category_id === 0 ? (
            <Alert type="warning">
              {
                "Para cambiar de posición las notas super destacadas, por favor elija una sección."
              }
            </Alert>
          ) : (
            ""
          )}
          {superFeatured > 0 && category_id > 0 ? (
            <Alert type="info">
              {"Arrastre y suelte la nota deseada, para cambiarla de posición."}
            </Alert>
          ) : (
            ""
          )}
        </Container>
        <Container padding={"15px"}>
          {superFeatured > 0 && category_id > 0 ? (
            renderItems()
          ) : (
            <NewTable
              columns={newsTableColumns}
              data={news}
              loading={loading}
            />
          )}
        </Container>
        {totalPages > 0 && superFeatured <= 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={(page) => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default NewsScreen;
