import { colors } from '../config';
import icons from './icons'

export default {
  colors: {
    ...colors,
  },
  icons : {
    ...icons
  }
};
