import React, { useReducer, useEffect, useCallback } from "react";
import { notify } from "react-notify-toast";
import {
  Container,
  Pagination,
  ActivityIndicator,
  FileDrop,
  Row,
  TextInput,
  Divider,
  Button,
  Icon,
} from "@ranch/components";
import { Tabs, Tab } from "./styles";
import { getFilesByType, DisplayFiles, uploadFile, searchFiles } from "@ranch/models/files";
import { useTheme } from "styled-components";

const TABS = ["Subir archivos", "Imágenes", "Videos", "Audios", "Fuentes"];

const FileManager = ({ onFileSelected }) => {
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    selectedTabIdx: 0,
    filesLoaded: [],
    error: null,
    totalPages: 0,
    uploadingFile: false,
    searchTerm: '',
  });

  const {
    loading,
    selectedTabIdx,
    totalPages,
    filesLoaded,
    uploadingFile,
    searchTerm,
  } = state;

  const getFiles = async (selectedTabIdx, page) => {
    const api = searchTerm ? searchFiles : getFilesByType;

    const tabsType = {
      1: "image",
      2: "video",
      3: "audio",
      4: "source",
    };

    setState({ loading: true });

    const shouldLoadFileType = tabsType[selectedTabIdx];

    const [error, status, files, totalItems] = await api({
      page,
      type: shouldLoadFileType,
      value: searchTerm,
    });
    
    if(error || status !== 200 || files.length === 0) {
      setState({ error, loading: false, filesLoaded: [], totalPages: 0 });
      notify.show("Error al obtener archivos","error")
      return
    }
    
    setState({ loading: false, error, filesLoaded: files, totalPages : Math.ceil(totalItems / 20) });
  }

  const handleClearSearch = async () => {
    setState({ searchTerm : '', totalPages: 0 })
    await getFiles(selectedTabIdx)
  } 


  useEffect(() => {
    if (selectedTabIdx === 0) return
    getFiles(selectedTabIdx);

  }, [selectedTabIdx]);

  const handleFileSelection = (file) => {
    if (!file) return;
    onFileSelected(file);
  };

  const handleUploadFiles = async (files, withWaterMark = false, title = "SingleFile", caption = "SingleCaption") => {
    const file = files[0]; //only first file will upload
    if (file) {
      setState({ uploadingFile: true });
      let fileType = "image";

      if (file.type === "video/mp4") {
        fileType = "video";
      }
      if (["audio/ogg", "audio/mpeg", "audio/mp3"].includes(file.type)) {
        fileType = "audio";
      }

      if (window.location.href.includes("sources")) {
        fileType = "source";
      }

      const [error, status, fileUploaded] = await uploadFile({
        type: fileType,
        title,
        caption,
        file,
        withWaterMark,
      });

      if (status === 200 && fileUploaded && !error) {
        notify.show("Archivo subido correctamente", "success", 3000);
      }

      if (status !== 200) {
        notify.show(error, "error", 3000);
      }

      setState({ uploadingFile: false });
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Container>
          <ActivityIndicator />
        </Container>
      );
    }

    if (selectedTabIdx > 0) {
      const tabsType = {
        1: "image",
        2: "video",
        3: "audio",
        4: "source",
      };
      return (
        <>
        <Row color="rgb(237, 237, 237)" padding={20}>
          <TextInput 
            placeholder="Buscador de archivos ..."
            onChange={(e) => setState({ searchTerm: e.target.value})}
            defaultValue={searchTerm}
            style={{
              width:'100%'
            }}
          />
          <Divider />
          <Button
            color={theme.colors.primary}
            width={"40px"}
            onClick={async () => {
              setState({ totalPages : 0 })
              await getFiles(selectedTabIdx, 1)
            }}
          >
            <Icon name={"search"} style={{ fontSize: 14 }} />
          </Button>
          {state.searchTerm && <>
            <Divider />
            <Button
              color={theme.colors.primary}
              width={"40px"}
              onClick={handleClearSearch}
            >
              <Icon name={"trash"} style={{ fontSize: 14 }} />
            </Button>
          </>}
        </Row>
        <DisplayFiles
          files={filesLoaded}
          onFileSelected={handleFileSelection}
          fileType={tabsType[selectedTabIdx]}
        />
        </>
      );
    }

    return (
      <FileDrop
        handleDrop={handleUploadFiles}
        onFileChange={handleUploadFiles}
        isUploading={uploadingFile}
        allowedFiles={[
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
          "video/mp4",
          "audio/mpeg",
          "audio/mp3",
          "audio/ogg",
        ]}
      />
    );
  };

  return (
    <Container>
      <Tabs>
        {TABS.map((item, idx) => (
          <Tab
            key={`tab-${idx}`}
            selected={selectedTabIdx === idx}
            onClick={() => setState({ selectedTabIdx: idx, searchTerm: '', totalPages: 0})}
          >
            {item}
          </Tab>
        ))}
      </Tabs>
      {renderContent()}
      {filesLoaded.length && totalPages > 0 && (
        <Pagination
          totalRecords={totalPages}
          pageLimit={10}
          pageNeighbours={10}
          onPageChanged={async (page) => {
            await getFiles(selectedTabIdx, page.currentPage);
          }}
        />
      )}
    </Container>
  );
};

export default FileManager;
