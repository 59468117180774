import { api } from "@ranch/api";
import * as yup from "yup";

const notificationSchema = yup.object().shape({
  title: yup.string().required("Escriba el nombre del perfil."),
  fileId:  yup.number().min(1,"Seleccione una imagen").transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
  .required("Seleccione una imagen."),
  type: yup.string().required("Elija un tipo de notificación."),
  content: yup.string().required("Escriba el contenido de la notificación."),
});

const sendNotification = (content) =>
  api
    .post("/notifications", content)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

const sendCustomNotification = (content) => api
.post("/notifyCustom", content)
.then(({ status, data }) => [null, status, data])
.catch((error) => [error, null, null]);

const sendNewsNotification = (id) =>
  api
    .post("/notify/" + id)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

const sendMultimediaNotification = (id) =>
  api
    .post("/notifyMultimedia/" + id)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

export {
  notificationSchema,
  sendNotification,
  sendNewsNotification,
  sendMultimediaNotification,
  sendCustomNotification
};
