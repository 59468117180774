import { api } from '@ranch/api';

const formatModules = items =>
  items.map(item => {
    return {
      label: item.title,
      value: item.id,
    };
  });

const getModules = () => {
  return api
    .get('/modules')
    .then(({ status, data }) => [
      null,
      status,
      formatModules(data.modules || []),
    ])
    .catch(error => [error, null, null]);
};

export { getModules };
