import React, { useReducer, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { notify } from 'react-notify-toast';

import {
  Container,
  Table,
  PageHeader,
  Pagination,
} from '@ranch/components';
import {
  sendCustomNotification
} from '@ranch/models/notifications';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { deleteLiveLink, getLiveLinks } from '../models/liveLinks';
import { Button, Icon } from '../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import routes from '../config/routes';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const LiveLinksScreen = () => {
   const history = useHistory()
  const theme = useTheme();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    liveLinks: [],
    error: null,
    totalPages: 0,
  });


  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchLiveLinks = useCallback(async () => {
    setState({ loading: true, error: null, liveLinks: [] });
    const [error, status, { liveLinks }, totalPages] = await getLiveLinks(
      page
    );

    if (status !== 200 || error) {
      notify.show('Ha ocurrido un error.', 'error');
    }

    if (status === 200 && liveLinks.length === 0) {
        setState({ liveLinks, error: null, loading: false, totalPages });
      }

    if (status === 200 && liveLinks.length > 0) {
      setState({ liveLinks, error: null, loading: false, totalPages });
    }
  },[page])

  const handleSendNotification = async (title) => {
    if (title) {
      const confirm = window.confirm(
        "¿Deseas enviar una notificacion para todos los usuarios de la app?"
      );

      if (confirm) {
        const [error, status, data] = await sendCustomNotification({
          title
        });
        if (
          error ||
          status !== 200 ||
          data.message === "NOTIFICATION_NOT_SENT"
        ) {
          notify.show("Error al enviar notificacion", "error");
        } else {
          notify.show("Notificacion enviada correctamente", "success");
        }
      }
    }
  }

  const handleDeleteNote = async (liveLinkId) => {
    if (liveLinkId) {
      const confirm = window.confirm(
        "¿Deseas eliminar permanentemente este enlace?"
      );

      if (confirm) {
        const [error, status, _] = await deleteLiveLink(liveLinkId);
        if (error || status !== 200) {
          notify.show("Error al borrar el enlace", "error");
        } else {
          notify.show("Enlace borrado correctamente", "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }
  }

  useEffect(() => {
    fetchLiveLinks();
  }, [fetchLiveLinks]);

  const liveLinksColumns = [
    {
      Header: 'Título',
      accessor: 'name',
      width: 400,
    },
    {
      Header: 'Enlace',
      accessor: 'url',
      width: 400,
    },
    {
      Header: 'Notificación',
      width: 400,
      Cell: ({ row }) => {
        const { notification_title } = row.original;
        return <span>{notification_title}</span>;
      },
    },
    {
      Header: 'Visible',
      width: 400,
      Cell: ({ row }) => {
        const { is_published } = row.original;
        return <span>{is_published ? 'SI' : 'No'}</span>;
      },
    },
    {
      Header: 'Acciones',
      width: 120,
      Cell: (cellData) => {
        const notTitle = cellData.row.original.notification_title
        const id = cellData && cellData.row ? cellData.row.original.id : cellData.id;
        const iconStyle = { color: theme.colors.black, fontSize: 18 };
        return (
          <Container
            color={"transparent"}
            row
            justify={"space-between"}
            padding={"0px 15px 0 0"}
          >
            <div onClick={() => history.push(routes.LIVE_LINKS_ITEM(id))}>
              <Icon name="eye" style={iconStyle} />
            </div>
            <div onClick={() => handleSendNotification(notTitle)}>
              <Icon name="bell" style={iconStyle} />
            </div>
            <div onClick={() => handleDeleteNote(id)}>
              <Icon name="trash" style={iconStyle} />
            </div>
          </Container>
        );
      },
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Enlaces En Vivo</PageHeader>
          <Button
            width={"160px"}
            onClick={() => history.push(routes.LIVE_LINKS_ITEM("new"))}
          >
            Crear Nuevo
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table
            columns={liveLinksColumns}
            data={state.liveLinks}
            loading={state.loading}
          />
        </Container>
        {state.totalPages > 0 && (
          <Pagination
            totalRecords={state.totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default LiveLinksScreen;
