import React, { useReducer, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import {
  Container,
  PageHeader,
  TextInput,
  Divider,
  Button,
  Column,
  Error,
  TagsInput,
  FileManager,
  ImageBackground,
} from '@ranch/components';
import {
  weatherSchema,
  getForecast,
  createUpdateForecast,
} from '@ranch/models/weather';
import moment from 'moment';
import ModalContext from '@ranch/contexts/ModalContext';
import { setModal, hideModal } from '@ranch/contexts/ModalContext/actions';
import { notify } from 'react-notify-toast';
import { settings, routes } from '@ranch/config';

const WeatherDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
    forecastImage: null,
  });

  const { saving, forecastImage } = state;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(weatherSchema),
  });

  const startsAtWatcher = watch('starts_at');

  const onSubmit = async data => {
    setState({ saving: true });
    const submitType = id && id > 0 ? 'update' : 'create';

    const forecast = {
      ...data,
    };

    if (id && id > 0) {
      forecast.id = id;
    }

    const [error, status, { message }] = await createUpdateForecast(
      forecast,
      submitType
    );

    if (status === 200) {
      if (message === 'UPDATED_FORECAST') {
        notify.show('Pronóstico actualizado correctamente.', 'success');
      }

      if (message === 'CREATED_FORECAST') {
        notify.show('Pronóstico creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.FORECAST);
        }, 1500);
      }
    }

    if (status === 202) {
      if (message === 'ERROR_UPDATING_FORECAST') {
        notify.show('Error al actualizar pronóstico.', 'error');
      }

      if (message === 'ERROR_CREATING_FORECAST') {
        notify.show('Error al crear pronóstico.', 'error');
      }
    }

    setState({ saving: false });
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const initialize = async () => {
    register({ name: 'file_id', value: null });
    if (id && Number(id)) {
      const [error, status, data] = await getForecast(id);

      if (status === 200 && data) {
        setValue('title', data.title);
        setValue('description', data.description);
        setValue('maxTempC', data.maxTempC);
        setValue('maxTempF', data.maxTempF);
        setValue('minTempC', data.minTempC);
        setValue('minTempF', data.minTempF);
        setValue('starts_at', moment(data.starts_at).format('YYYY-MM-DD'));
        setValue('file_id', data.file_id);
        setState({ forecastImage: data?.image?.small_url });
      }
    }
  };

  const handleSelectedFile = file => {
    //id, type, url
    setValue('file_id', file.id);
    setState({ forecastImage: file.url });
    hideModal(modalDispatch);
  };

  useEffect(() => {
    initialize();
  }, []);

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: 'Administrador de archivos',
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Detalles del Pronóstico</PageHeader>
        </Container>
        <Column padding={'15px'}>
          <Container width={'40%'}>
            {forecastImage && <ImageBackground src={forecastImage} large />}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>
              Imagen del Pronóstico
            </Button>
          </Container>
          <Divider size={20} />
          <TextInput
            name={'title'}
            label={'Título'}
            ref={register}
            type={'text'}
            error={hasError('title')}
          />
          <Divider size={20} />
          <TextInput
            name={'description'}
            label={'Descripción'}
            ref={register}
            type={'text'}
            error={hasError('description')}
          />
          <Divider size={20} />
          <Container>
            <Container row>
              <Container>
                <TextInput
                  name={'starts_at'}
                  label={'Fecha de inicio'}
                  ref={register}
                  type={'date'}
                  error={hasError('starts_at')}
                />
              </Container>
              <Divider />
              <Container row>
                <Container row>
                  <Container>
                    <TextInput
                      name={'minTempC'}
                      label={'Min. Centígrados'}
                      ref={register}
                      type={'text'}
                      error={hasError('minTempC')}
                    />
                  </Container>
                  <Divider />
                  <Container>
                    <TextInput
                      name={'maxTempC'}
                      label={'Max. Centígrados'}
                      ref={register}
                      type={'text'}
                      error={hasError('maxTempC')}
                    />
                  </Container>
                </Container>
                <Divider />
                <Container row>
                  <Container>
                    <TextInput
                      name={'minTempF'}
                      label={'Min. Farenheit'}
                      ref={register}
                      type={'text'}
                      error={hasError('minTempF')}
                    />
                  </Container>
                  <Divider />
                  <Container>
                    <TextInput
                      name={'maxTempF'}
                      label={'Max. Farenheit'}
                      ref={register}
                      type={'text'}
                      error={hasError('maxTempF')}
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Pronóstico
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default WeatherDetails;
