import React from 'react'
import { Wrapper, Label, Error } from './style'

const SingleTextInput = ((props) => (
    <React.Fragment>
        <Label>{props.label}</Label>
        <Wrapper {...props}/>
        {props.error && <Error>{props.error}</Error>}
    </React.Fragment>
))

export default SingleTextInput