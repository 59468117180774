import React, { useReducer, useEffect } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { notify } from "react-notify-toast";
import { settings, routes } from "@ranch/config";
import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Button,
  Icon,
  Dropdown,
  Divider,
} from "@ranch/components";
import useQueryParams from "@ranch/hooks/useQueryParams";
import { getPosts, deletePost } from "@ranch/models/posts";
import moment from "moment";

const DEFAULT_QUERY_VALUES = {
  page: 0,
  type: "general",
  published: 0,
};

const typesOptions = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Empleo",
    value: "empleo",
  },
];

const PostsScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    posts: [],
    error: null,
    totalPages: 0,
  });

  const { loading, posts, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page, type, published } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchPosts = async () => {
    setState({ loading: true, error: null, weather: [] });
    const [error, status, { posts }, totalPages] = await getPosts(
      page,
      type,
      published
    );

    if (status !== 200 || error) {
      notify.show("Ha ocurrido un error.", "error");
    }

    if (status === 200 && posts) {
      setState({ posts, error: null, loading: false, totalPages });
    }
  };

  const handleDeletePost = async (postId) => {
    if (postId) {
      const confirm = window.confirm(
        "¿Deseas eliminar permanentemente esta registro?"
      );

      if (confirm) {
        const [error, status, data] = await deletePost(postId);
        if (error || status !== 200) {
          notify.show("Error al borrar el registro", "error");
        } else {
          notify.show("Registro borrado correctamente", "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }

    return;
  };

  useEffect(() => {
    fetchPosts();
  }, [page, type, published]);

  const renderActions = (cellData) => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={"transparent"}
        row
        justify={"space-between"}
        padding={"0px 15px 0 0"}
      >
        <div onClick={() => history.push(routes.POST_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        <div onClick={() => handleDeletePost(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const postColumns = [
    {
      Header: "Título",
      accessor: "title",
      width: 400,
    },
    {
      Header: "Descripción",
      accessor: "content",
      width: 400,
    },
    {
      Header: "Categoría",
      accessor: "type",
      width: 400,
    },
    {
      Header: "Fecha",
      accessor: "created_at",
      width: 400,
      Cell: ({ row }) => (
        <span>{moment(row.original.created_at).format("LLL")}</span>
      ),
    },
    {
      Header: "Publicada",
      width: 400,
      accessor: "published",
      Cell: ({ row }) => (
        <span>{row.original.published == 1 ? "Si" : "No"}</span>
      ),
    },
    {
      Header: "Acciones",
      accessor: "",
      width: 150,
      Cell: (cell) => renderActions(cell),
    },
  ];

  const Filters = () => {
    return (
      <Container>
        <h4>Búsqueda</h4>
        <Container row justify={"space-between"}>
          <Dropdown
            title={"Publicada"}
            options={settings.publishedValues}
            value={settings.publishedValues.find(
              (item) => item.value == published
            )}
            name={"published"}
            onChange={(e) => setQueryParam({ published: e.value })}
          />
          <Divider />
          <Dropdown
            title={"Categoría"}
            options={typesOptions}
            name={"type"}
            value={typesOptions.find((item) => item.value == type)}
            onChange={(e) => setQueryParam({ type: e.value })}
          />
          <Divider />
        </Container>
      </Container>
    );
  };

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container row padding={"15px"} justify={"space-between"} flex={0}>
          <PageHeader>Servicio Social</PageHeader>
          <Button
            width={"160px"}
            onClick={() => history.push(routes.POST_ITEM("new"))}
          >
            Crear Nuevo
          </Button>
        </Container>
        <Container padding={"15px"} flex={"0"}>
          <Filters />
        </Container>

        <Container padding={"15px"}>
          <Table columns={postColumns} data={posts} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={(page) => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default PostsScreen;
