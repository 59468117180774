import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Wrapper } from './style';
import { Column, Row, Icon, Divider } from '../../../components';
import logo from '../../../assets/logo.png';

const Header = () => {
  return (
    <Wrapper>
      <Row align={'center'} justify={'space-between'} padding={'0 20px'}>
        <img src={logo} style={{ width: 125, height: 55 }} />
      </Row>
    </Wrapper>
  );
};

export default Header;
