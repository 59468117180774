import React from 'react';
import styled, { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Icon } from '../../../components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  flex: 1;
`;

const ItemWrapp = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 300ms ease;
  padding: 5px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  margin: 20px 0;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Item = props => {
  return (
    <React.Fragment>
      <ItemWrapp data-tip={props.title} {...props}>
        {props.children}
      </ItemWrapp>
      <ReactTooltip effect={'solid'} />
    </React.Fragment>
  );
};

const CircleWrap = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 0 1px 5px ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleItem = ({ onClick }) => {
  const theme = useTheme();
  return (
    <ItemWrapp data-tip={'Nueva Noticia'}>
      <CircleWrap onClick={onClick}>
        <Icon
          name={'plus'}
          style={{
            color: theme.colors.white,
            fontSize: 16,
            fontWeight: 'bold',
          }}
        />
      </CircleWrap>
      <ReactTooltip effect={'solid'} />
    </ItemWrapp>
  );
};

export { Wrapper, Item, CircleItem };
