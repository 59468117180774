import { api } from '@ranch/api';

const getSalutations = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/salutations', { params: endpointProps })
    .then(({ status, data }) => {
      const { salutations } = data;
      const totalPages = salutations?.total || 0;
      return [
        null,
        status,
        { salutations: salutations?.data || [] },
        totalPages,
      ];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const createOrUpdateSalutation = (program, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${program.id}`;
  const url = `/salutations${params}`;

  return api[action](url, program)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

export { getSalutations, createOrUpdateSalutation };
