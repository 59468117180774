import React from "react";
import Select from "react-select";

const Dropdown = ({
  title,
  options,
  value,
  onChange,
  isLoading = false,
  isDisabled,
  defaultValue,
  style,
  ...rest
}) => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", flex: 1, ...style }}
    >
      <span style={{ display: "block", marginBottom: 8 }}>{title}</span>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        isLoading={isLoading}
        defaultValue={defaultValue}
        styles={customStyles}
        isDisabled={isDisabled}
        {...rest}
      />
    </div>
  );
};

export default Dropdown;
