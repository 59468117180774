import styled from 'styled-components'

const Row = styled.div`
    display:flex;
    flex-direction:row;
    ${({ align }) => align && `align-items:${align}`};
    ${({ justify }) => justify && `justify-content:${justify}`};
    ${({ width }) => width && `width:${width}`};
    ${({ wrap }) => wrap && `flex-wrap:${wrap}`};
    background-color:${({theme, color}) => color ? color : theme.colors.white};
    height:100%;
    ${({ padding }) => padding && `padding:${padding}`};
`

export default Row