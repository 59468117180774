import React from 'react'
import { Wrapper, Label, Error } from './style'

const TextInput = React.forwardRef((props, ref) =>  (
    <React.Fragment>
        <Label>{props.label}</Label>
        <Wrapper {...props} ref={ref} />
        {props.error && <Error>{props.error}</Error>}
    </React.Fragment>
))

export default TextInput