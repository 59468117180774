import React, { useContext } from 'react';
import { FileManager } from '@ranch/components';
import ModalContext from '@ranch/contexts/ModalContext';
import { setModal, hideModal } from '@ranch/contexts/ModalContext/actions';
import {
  Modifier,
  EditorState,
  ContentState,
  convertFromHTML,
  AtomicBlockUtils,
} from 'draft-js';

const FileSelector = ({ editorState, onChange }) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const handleEditorAttachment = () => {
    const handleFileSelected = file => {
      let attachment = '';
      if (file.type === 'image') {
        attachment = `<img src="${file.url}" width="100%" height="100%" alt="Rancherita imagen" />`;

        const blocksFromHtml = convertFromHTML(attachment);
        const { contentBlocks, entityMap } = blocksFromHtml;

        const contentState = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          ).getBlockMap()
        );

        onChange(
          EditorState.push(editorState, contentState, 'insert-fragment')
        );
      }

      if (file.type === 'video') {
        const src = file.url;
        const height = 'auto';
        const width = 'auto';
        const entityKey = editorState
          .getCurrentContent()
          .createEntity('EMBEDDED_LINK', 'MUTABLE', { src, height, width })
          .getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        );
        onChange(newEditorState);
      }

      hideModal(modalDispatch);
    };

    setModal(modalDispatch, {
      title: 'Administrador de archivos',
      children: <FileManager onFileSelected={handleFileSelected} />,
      useButton: false,
      extraProps: {},
    });
  };

  return (
    <div
      onClick={handleEditorAttachment}
      className="rdw-image-wrapper"
      aria-haspopup="true"
      aria-label="rdw-image-control"
      aria-expanded="false"
    >
      <div className="rdw-option-wrapper" title="Image">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4="
          alt=""
        />
      </div>
    </div>
  );
};

export default FileSelector;
