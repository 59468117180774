import React, { useReducer, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Button,
  Icon,
  ImageBackground,
} from '@ranch/components';
import { settings, routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getPolls } from '@ranch/models/polls';
import moment from 'moment';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const PollsScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    polls: [],
    error: null,
    totalPages: 0,
  });

  const { loading, polls, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchPolls = async (page) => {
    setState({ loading: true, error: null, polls: [] });
    const [error, status, data, totalPages] = await getPolls({page});

    if (status !== 200 || error) {
      notify.show('Ha ocurrido un error.', 'error');
    }

    if (status === 200 && data) {
      setState({ polls: data.polls, error: null, loading: false, totalPages });
    }
  };

  useEffect(() => {
    fetchPolls(page);
  }, [page]);

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        justify={'space-between'}
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.POLL_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const pollsTableColumns = [
    {
      Header: '#',
      accessor: 'image',
      width: 100,
      Cell: ({ value, cell }) => 
      <div onClick={() => history.push(routes.POLL_ITEM(cell.row.original.id))}>
        <ImageBackground src={value} />
      </div>,
    },
    {
      Header: 'Título',
      accessor: 'title',
      width: 250,
      Cell: ({ value, cell }) =>  <div onClick={() => history.push(routes.POLL_ITEM(cell.row.original.id))}><span>{value}</span></div>
    },
    {
      Header : 'Resultados',
      accessor:'results',
      width : 200,
      Cell:({value, cell}) => {
        let allVotes = 0;
        cell.row.original.results.forEach(item => allVotes = allVotes + item.votes)

        return <div>
          <div style={{height:10}}/>
          {cell.row.original.results.map(item => <div style={{
            padding:'5px 0'
          }}>
            <span>{item.title}</span>
            <div style={{
              maxWidth: '100%',
              width : `${((item.votes * 100) / allVotes)}%`,
              height:20,
              backgroundColor:'gray'
            }}></div>
            {item.votes} | <strong>{parseFloat((item.votes * 100) / allVotes).toFixed(2)}%</strong>
          </div>)}
          <br/>
          Total de Respuestas : <strong>{allVotes}</strong> 
          <div style={{height:10}}/>
        </div>
      }
    },
    {
      Header: 'Comienza',
      accessor: 'starts_at',
      width: 200,
      Cell: cell => {
        const { starts_at } = cell.row.original;
        return moment(starts_at).format('LL');
      },
    },
    {
      Header: 'Termina',
      accessor: 'ends_at',
      Cell: cell => {
        const { ends_at } = cell.row.original;
        return moment(ends_at).format('LL');
      },
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Encuestas</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.POLL_ITEM('new'))}
          >
            Crear Encuesta
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table columns={pollsTableColumns} data={polls} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default PollsScreen;
