export default {
  white: '#ffffff',
  black: '#323232',
  primary: '#e7554b',
  gray: '#494949',
  grayLight: '#ededed',
  grayDark: '#d9d9d9',
  primaryDark: '#2E387B',
  secondary: '#E94E72',
  third: '#7367F0',
  blackLight: '#868786',
  gray1: '#5F5F5F',
  gray2: '#EDEDED',
  gray3: '#E4E3E7',
  gray4: '#777777',
  gray5: '#737373',
  gray6: '#F1F1F1',
  purple: '#D5D1FB',
  green: '#28C76E',
  gray7: '#979797',
};
