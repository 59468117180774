import { api } from '@ranch/api';

const formatCategories = items =>
  items.map(item => {
    return {
      label: item.title,
      value: item.id,
    };
  });

const getCategories = returnCategoriesFormatted => {
  return api
    .get('/categories')
    .then(({ status, data }) => {
      let categories = data;
      if (returnCategoriesFormatted) {
        categories = { categories: formatCategories(data.categories) };
      }
      return [null, status, categories];
    })
    .catch(error => {
      return [error, null, null];
    });
};

export { getCategories };
