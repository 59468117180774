import React, { useEffect, useReducer, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Container,
  PageHeader,
  TextInput,
  Column,
  Divider,
  FileManager,
  Button,
  Text,
  AudioSelectorInput,
} from "@ranch/components";
import ModalContext from "@ranch/contexts/ModalContext";
import { setModal, hideModal } from "@ranch/contexts/ModalContext/actions";
import { settings } from "@ranch/config";
import {
  getMultimediaDetails,
  multimediaDetailsSchema,
  createUpdateMultimedia,
} from "@ranch/models/multimedia";
import { notify } from "react-notify-toast";

const MultimediaDetails = () => {
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const history = useHistory();

  const _handleBack = () => history.goBack()

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    videoFile: null,
    saving : false
  });

  const { videoFile, saving } = state;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { register, handleSubmit, errors, setValue, getValues, watch } =
    useForm({
      shouldUnregister: false,
      resolver: yupResolver(multimediaDetailsSchema),
    });

  watch('video_id', '');
  watch('audios', []);

  const initialize = useCallback(() => {
    register({ name: 'audios', value: [] });
  },[register]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onSubmit = async (data) => {
    setState({saving : true})
    const submitType = id && id > 0 ? "update" : "create";
    const submitMessageType = id && id > 0 ? "actualizar" : "crear";
    const submitSuccessMessage = id && id > 0 ? "actualizado" : "creado";

    if (id && id > 0) {
        data.id = id;
    }

    const formattedDetails = {
      ...data,
    };

    const [error, status, newsCreateUpdateResponse] = await createUpdateMultimedia(
      formattedDetails,
      submitType
    );

    if (status === 200) {
      if (error) {
        setState({saving : false})
        notify.show("Error al " + submitMessageType + " elemento multimedia.", "error");
      }
      if (newsCreateUpdateResponse) {
        notify.show(
          "Elemento multimedia " + submitSuccessMessage + " correctamente.",
          "success"
        );

        if (submitType === "create") {
          history.push("/dashboard/multimedia");
        }
      }
    }
  };

  
  const findDetails = useCallback(async (id) => {
    register({ name: "audios", value: [] });
    if (id) {
      const [error, status, data] = await getMultimediaDetails(id);

      if (status === 200 && data) {
        setValue("title", data.title);
        setValue("youtube_url", data.youtube_url);

        if(data.video){
            setValue('video_id',data.video.id)
            setState({ videoFile: data.video.small_url });
        }
        
        let formattedAudios = [];
        if (data.audios && data.audios.length) {
          formattedAudios = data.audios.map((item) => {
            return {
              fileId: item.id,
              url: `${settings.imagesUrl}${item.small_url}`,
              title: item.title
            };
          });
        }
        setValue("audios", formattedAudios);
      }
    }
  } , [register, setValue])

  useEffect(() => {
    findDetails(id);
  }, [findDetails, id]);

  const hasError = (field) => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const handleSelectedFile = (file) => {
    //id, type, url
    setState({ videoFile: file.url });
    hideModal(modalDispatch);
    setTimeout(() => {
      setValue("video_id", file.id);
    }, 250);
  };

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: "Administrador de archivos",
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  const handleAudioChange = (audios) => {
    setValue("audios", audios);
  };

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"} row>
          <Container>
            <PageHeader>Detalles del elemento multimedia</PageHeader>
          </Container>
        </Container>
        <Column padding={"15px"}>
          <Container width={"40%"}>
            {videoFile && (
                <video>
                    <source src={videoFile}></source>
                </video>
            )}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>Video del elemento multimedia</Button>
            <Divider size={10} />
            {errors["videoFile"] && (
              <Text size={12} color={theme.colors.primary}>
                {errors["videoFile"].message}
              </Text>
            )}
          </Container>
          <Divider size={20} />
          <TextInput
            name={"title"}
            label={"Título"}
            ref={register}
            type={"text"}
            error={hasError("title")}
          />
          <Divider size={20} />
          <Container>
            <TextInput
                name={"youtube_url"}
                label={"Enlace de Youtube"}
                ref={register}
                type={"text"}
                error={hasError("youtube_url")}
                placeholder='https://youtube...'
            />
            <Divider size={10} />
            <Text size={12} color={theme.colors.primary}>
                No llenar si se ha seleccionado un video desde la galería.
            </Text>
          </Container>
          
          <Divider size={20} />
          <Container>
            <AudioSelectorInput
              handleChange={handleAudioChange}
              items={getValues("audios") || []}
            />
            <Divider size={20} />
          </Container>
          <Divider size={20} />
          {!saving && <Container row justify={'space-between'}>
            <Container width={"20%"}>
              <Button onClick={handleSubmit(onSubmit)}>Guardar</Button>
            </Container>
            <Container width={"20%"}>
              <Button background={'transparent'} color={theme.colors.primary} onClick={_handleBack}>Salir</Button>
            </Container>
          </Container>}
        </Column>
      </Container>
    </Container>
  );
};

export default MultimediaDetails;
