import styled from 'styled-components';

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${({ theme, color }) => color || theme.colors.black};
  font-size: 15px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'montserrat-regular';

  ${({ separated }) =>
    separated &&
    `
        padding-left:10px;
    `};

  ${({ labelColor }) => labelColor && `color:${labelColor}`};
`;

const Error = styled.span`
  color: red;
  font-family: 'montserrat-medium';
  text-aling: left;
  font-size: 12px;
  margin-top: 8px;

  ${({ color }) => color && ` color : ${color} `};
`;

export { Error, File, Wrapper, Label };
