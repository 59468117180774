import React, { useEffect, useContext, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTheme } from 'styled-components';
import { notify } from 'react-notify-toast';
import {
  Container,
  PageHeader,
  TextInput,
  Column,
  Divider,
  Dropdown,
  FileManager,
  Button,
  Error,
  TextArea,
  DeviceNotification,
} from '@ranch/components';
import {
  sendCustomNotification,
  notificationSchema,
} from '@ranch/models/notifications';
import { settings } from '@ranch/config';
import ModalContext from '@ranch/contexts/ModalContext';
import { setModal, hideModal } from '@ranch/contexts/ModalContext/actions';

const NotificationsScreen = () => {
  const theme = useTheme();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
   fileId : 0,
   fileUrl: '',
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(notificationSchema),
  });

  watch('title');
  watch('content');
  watch('type');
  watch('fileId')

  const isValidURL = (url) => {
    // Regular expression to match URLs
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol (http or https)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    return urlPattern.test(url);
  }

  const onSubmit = async (notification) => {

    if(notification.type === 'link'){
      const isValid = isValidURL(notification.content)
      if(!isValid){
        notify.show("El contenido no tiene un enlace válido", "error")
        return
      }
    }

    const confirm = window.confirm(
      "¿Deseas enviar una notificacion para todos los usuarios de la app?"
    );

    if (confirm) {
      const notiData = {
        ...notification,
        fileId: state.fileId
      }
      const [error, status, data] = await sendCustomNotification(notiData);
      if (
        error ||
        status !== 200 ||
        data.message === "NOTIFICATION_NOT_SENT"
      ) {
        notify.show("Error al enviar notificacion", "error");
      } else {
        notify.show("Notificacion enviada correctamente", "success");
      }
    }
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const initialize = () => {
    register({ name: 'type', value: '' });
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleSelectedFile = file => {
    setValue('fileId',file.id)
    setState({ fileId: file.id, fileUrl: file.url })
    hideModal(modalDispatch)
  } 

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: 'Administrador de archivos',
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'15px'}>
        <Container padding={'15px'} justify={'space-between'} flex={'0'}>
          <PageHeader>Notificaciones</PageHeader>
        </Container>
        <Container row>
          <Column padding={'15px'} width={'50%'}>
            <TextInput
              name={'title'}
              label={'Título'}
              ref={register}
              type={'text'}
              error={hasError('title')}
            />
            <Divider />
            <Container row flex={'0'}>
              <Column>
                <span>Imagen de la notificación</span>
                <Divider size={8} />
                <Button width={'250px'} onClick={openFileManager}>Seleccione una imagen</Button>
                <TextInput
                  name={'fileId'}
                  label={''}
                  ref={register}
                  type={'number'}
                  error={hasError('fileId')}
                  style={{
                    display:'none'
                  }}
                />
              </Column>
              <Divider />
              <Container flex={1}>
                <Dropdown
                  title={'Tipo'}
                  options={settings.notificaciontsType}
                  defaultValue={settings.notificaciontsType.find(
                    item => item.value == 0
                  )}
                  value={settings.notificaciontsType.find(
                    item => item.value == getValues('type')
                  )}
                  name={'type'}
                  onChange={e => setValue('type', e.value)}
                />
                <Error>{hasError('type')}</Error>
              </Container>
            </Container>
            <Divider />
            <Container>
              <TextArea
                label={'Contenido de la notificación'}
                name="content"
                ref={register}
                error={hasError('content')}
              />
            </Container>
            <Divider size={20} />
            <Container width={'40%'}>
              <Button onClick={handleSubmit(onSubmit)}>
                Enviar Notificación
              </Button>
            </Container>
          </Column>
          <Column width={'50%'}>
            <DeviceNotification
              title={getValues('title')}
              image={state.fileUrl}
              content={getValues('content')}
              type={getValues('type')}
            />
          </Column>
        </Container>
      </Container>
    </Container>
  );
};

export default NotificationsScreen;
