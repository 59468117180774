import React, { useEffect, useReducer, useContext } from 'react';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  PageHeader,
  FileManager,
  TextInput,
  Divider,
  Button,
  Column,
  Error,
  Dropdown,
  ImageBackground,
} from '@ranch/components';
import {
  getAnnouncer,
  announcerSchema,
  createUpdateAnnouncer,
} from '@ranch/models/announcers';
import ModalContext from '@ranch/contexts/ModalContext';
import { setModal, hideModal } from '@ranch/contexts/ModalContext/actions';
import { notify } from 'react-notify-toast';
import { routes, settings } from '@ranch/config';

const AnnouncersDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
    announcerImage: '',
  });

  const { saving, announcerImage } = state;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(announcerSchema),
  });

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onSubmit = async data => {
    setState({ saving: true });

    const submitType = id && id > 0 ? 'update' : 'create';

    if (id && id > 0) {
      data.id = id;
    }

    const [error, status, { message, errors }] = await createUpdateAnnouncer(
      data,
      submitType
    );

    if (error) {
      notify.show(error, 'error');
    }

    if (status === 200) {
      if (
        (message === 'ANNOUNCER_NOT_CREATED' ||
          message === 'ANNOUNCER_NOT_UPDATED') &&
        errors
      ) {
        let formattedErrors = '';
        Object.keys(errors).forEach(item => {
          return (formattedErrors += `${errors[item]} | `);
        });
        notify.show(formattedErrors, 'error');
      }

      if (message === 'UPDATED_ANNOUNCER') {
        notify.show('Locutor actualizado correctamente.', 'success');
      }

      if (message === 'CREATED') {
        notify.show('Locutor creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.ANNOUNCERS);
        }, 1500);
      }
    }

    if (status !== 200) {
      notify.show('Error al actualizar locutor.', 'error');
    }

    setState({ saving: false });
  };

  const handleSelectedFile = file => {
    setValue('file_id', file.id);
    setState({ announcerImage: file.url });
    hideModal(modalDispatch);
  };

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: 'Administrador de archivos',
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  const fetchAnnouncer = async id => {
    register({ name: 'file_id', value: 0 });
    register({ name: 'published', value: 0 });
    if (id && Number(id)) {
      const [error, status, announcer] = await getAnnouncer(id);
      if (status === 200 && announcer) {
        setValue('firstName', announcer.firstName);
        setValue('lastName', announcer.lastName);
        setValue('programs', announcer.programs);
        setValue('schedules', announcer.schedules);
        setValue('comments', announcer.comments);

        const published = settings.publishedValues.find(
          item => item.value === announcer.published
        );

        setValue('published', published ? published.value : 0);
        setValue('phrases', announcer.phrases);
        setValue('email', announcer.email);
        setValue('file_id', announcer.file_id);
        setState({ announcerImage: announcer?.image?.small_url });
      }
    }
  };

  useEffect(() => {
    fetchAnnouncer(id);
  }, [id]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Detalles del Locutor</PageHeader>
        </Container>
        <Column padding={'15px'} flex={0}>
          <Container width={'40%'}>
            {announcerImage && (
              <ImageBackground src={announcerImage} size={90} />
            )}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>
              Imagen del locutor
            </Button>
          </Container>
          <Divider size={20} />
          <TextInput
            name={'firstName'}
            label={'Nombre'}
            ref={register}
            type={'text'}
            error={hasError('firstName')}
          />
          <Divider size={20} />
          <TextInput
            name={'lastName'}
            label={'Apellido'}
            ref={register}
            type={'text'}
            error={hasError('lastName')}
          />
          <Divider size={20} />
          <TextInput
            name={'email'}
            label={'Email'}
            ref={register}
            type={'email'}
            error={hasError('email')}
          />
          <Divider size={20} />
          <Dropdown
            title={'Activo'}
            options={settings.publishedValues}
            name={'published'}
            defaultValue={settings.publishedValues.find(
              item => item.value == 0
            )}
            value={settings.publishedValues.find(
              item => item.value == getValues('published')
            )}
            onChange={e => {
              setValue('published', e.value);
            }}
            style={{ flex: 0 }}
          />
          {hasError('published') && <Error>{hasError('published')}</Error>}
          <Divider size={20} />
          <TextInput
            name={'programs'}
            label={'Programas'}
            ref={register}
            type={'text'}
            error={hasError('programs')}
          />
          <Divider size={20} />
          <TextInput
            name={'schedules'}
            label={'Horarios'}
            ref={register}
            type={'text'}
            error={hasError('schedules')}
          />
          <Divider size={20} />
          <TextInput
            name={'comments'}
            label={'Comentarios'}
            ref={register}
            type={'text'}
            error={hasError('comments')}
          />
          <Divider size={20} />
          <TextInput
            name={'phrases'}
            label={'Frases'}
            ref={register}
            type={'text'}
            error={hasError('phrases')}
          />
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Locutor
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default AnnouncersDetails;
