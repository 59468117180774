export default {
  DEV_ENDPOINT: "https://apiv3.rancherita.com.mx/public/api", // "http://localhost/lumen-rest-api/public/api", //https://rancherita.com.mx/apiv3/public/api
  PROD_ENDPOINT: "https://apiv3.rancherita.com.mx/public/api", // "https://rancherita.com.mx/apiv3/public/api",
  AUTHTOKEN: "authToken",
  imagesUrl: "https://rancherita.com.mx/uploads/images/",
  publishedValues: [
    {
      label: "Si",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ],
  isLocal: () => window.location.href.includes("localhost"),
  notificaciontsType: [
    {
      label: "Mensaje",
      value: "message",
    },
    {
      label: "Enlace",
      value: "link",
    },
  ],
  sourceType: [
    {
      label: "Interna",
      value: "interna",
    },
    {
      label: "Externa",
      value: "externa",
    },
  ],
};
