import React, { useReducer, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import {
  Container,
  PageHeader,
  TextInput,
  Divider,
  Button,
  Column,
  Dropdown,
  Error,
} from '@ranch/components';
import { postSchema, getPost, createUpdatePost } from '@ranch/models/posts';
import { notify } from 'react-notify-toast';
import { settings, routes } from '@ranch/config';

const typesOptions = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Empleo',
    value: 'empleo',
  },
];

const PostsDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
  });

  const { saving } = state;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(postSchema),
  });

  const typeWatcher = watch('type');
  const publishedWatcher = watch('published');

  const onSubmit = async data => {
    setState({ saving: true });
    const submitType = id && id > 0 ? 'update' : 'create';

    const post = {
      ...data,
    };

    if (id && id > 0) {
      post.id = id;
    }

    const [error, status, { message }] = await createUpdatePost(
      post,
      submitType
    );

    if (status === 200) {
      if (message === 'UPDATED_POST') {
        notify.show('Actualizado correctamente.', 'success');
      }

      if (message === 'CREATED_POST') {
        notify.show('Creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.POSTS);
        }, 1500);
      }
    }

    if (status === 202) {
      if (message === 'ERROR_UPDATING_POST') {
        notify.show('Error al actualizar.', 'error');
      }

      if (message === 'ERROR_CREATING_POST') {
        notify.show('Error al crear.', 'error');
      }
    }

    setState({ saving: false });
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const initialize = async () => {
    register({ name: 'type' });
    register({ name: 'published' });

    if (id && Number(id)) {
      const [error, status, data] = await getPost(id);

      if (status === 200 && data) {
        setValue('title', data.title);
        setValue('content', data.content);
        setValue('published', data.published);
        setValue('type', data.type);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Detalles</PageHeader>
        </Container>
        <Column padding={'15px'}>
          <TextInput
            name={'title'}
            label={'Título'}
            ref={register}
            type={'text'}
            error={hasError('title')}
          />
          <Divider size={20} />
          <TextInput
            name={'content'}
            label={'Descripción'}
            ref={register}
            type={'text'}
            error={hasError('content')}
          />
          <Divider size={20} />
          <Container row flex={'0'}>
            <Container>
              <Dropdown
                title={'Tipo'}
                options={typesOptions}
                name={'type'}
                value={typesOptions.find(
                  item => item.value == getValues('type')
                )}
                onChange={e => setValue('type', e.value)}
              />
              {hasError('type') && <Error>{hasError('type')}</Error>}
            </Container>
            <Divider />
            <Container>
              <Dropdown
                title={'Publicada'}
                options={settings.publishedValues}
                value={settings.publishedValues.find(
                  item => item.value == getValues('published')
                )}
                name={'published'}
                onChange={e => setValue('published', e.value)}
              />
              {hasError('published') && <Error>{hasError('published')}</Error>}
            </Container>
          </Container>
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default PostsDetails;
