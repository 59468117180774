import styled from 'styled-components'

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    height:70px;
    border-bottom:1px solid ${({ theme }) => theme.colors.grayDark};
`

export {
    Wrapper
}