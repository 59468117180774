import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { api } from "@ranch/api";
import { settings, colors } from "@ranch/config";
import { Container, Button, Divider, Text, Row, Icon, Column } from "@ranch/components";
import * as yup from 'yup';
import { store } from '@ranch/config';
import { notify } from 'react-notify-toast';


const fileUploadSchema = yup.object().shape({
  title: yup
    .string()
    .required('Este campo es requerido'),
  caption: yup.string().required('Este campo es requerido'),
});


const formatFiles = (items) =>
  items.map((item) => {
    return {
      id: item.id,
      title: item.title,
      smallUrl: `${settings.imagesUrl}${item.small_url}`,
      bigUrl: `${settings.imagesUrl}${item.big_url}`,
      type: item.fileType,
    };
  });

const getFilesByType = ({ type = "image", page = 0 }) => {
  return api
    .get("/files", {
      params: {
        page,
        type,
      },
    })
    .then(({ status, data }) => {
      return [null, status, formatFiles(data.files), data.totalItems];
    })
    .catch((error) => {
      return [error, null, [], 0];
    });
};

const deleteFile = (fileId) =>  api
    .delete("/files/" + fileId)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

const searchFiles = (data) => api
.post("/files/search", data)
.then(({ status, data }) => [null, status, formatFiles(data.files), data.totalItems])
.catch((error) => [error, null, [], 0]);

const uploadFile = ({
  type = "image",
  title = "Uploaded image",
  caption = "Upload caption",
  file,
  withWaterMark = false,
}) => {
  const form = new FormData();
  form.append("title", title);
  form.append("caption", caption);
  form.append("file", file);
  form.append("withWaterMark", withWaterMark);

  const options = {
    image: "/image",
    video: "/video",
    audio: "/audio",
    source: "/sourceImage",
  };

  const url = options[type];
  return api
    .post(url, form)
    .then(({ status, data }) => {
      return [null, status, data.file];
    })
    .catch((error) => {
      return [error.message, null, null];
    });
};

const File = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 150px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  transition: all 300ms ease;
  cursor: pointer;
  margin-right:13px;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

const DisplayFiles = ({ files, onFileSelected }) => {
  const theme = useTheme();
  const [canDelete, setDeletePermission] = useState(false);

  const setPermissions = () => {
    const permissions = JSON.parse(store.getItem('modules'));
    const hasDeletePermission = permissions.find((item) => item.slug === 'deleteFiles');
    setDeletePermission(hasDeletePermission);
  }

  const handleDeleteFile = async (itemId) => {
    if(!itemId) return;

    const confirm = window.confirm('¿Seguro que deseas eliminar este archivo?');
    if(!confirm) return
    
    const [error, status, _] = await deleteFile(itemId);
    if (error || status !== 200) {
      notify.show("Error al borrar el archivo", "error");
      return
    }

    notify.show("Archivo borrado correctamente", "success");
    setTimeout(() => window.location.reload(), 2000);
  }


  useEffect(() => {
    setPermissions()
  },[])

  const renderItems = () => {
    if(files.length === 0){
      return <Text size={18} color={"#000"}>
        Sin elementos para mostrar.
      </Text>
    }

    return files.map((item, idx) => {
      if (item.type === "audio") {
        return (
          <Container
            key={`audio-${idx}`}
            width={"300px"}
            color={"transparent"}
            style={{
              marginRight:13,
            }}
          >
            <audio controls>
              <source src={item.smallUrl} type="audio/ogg" />
              <source src={item.smallUrl} type="audio/mpeg" />
              Your browser does not support the audio tag.
            </audio>
            <Divider size={10} />
            <Row color='transparent' justify="center">
              <Button
                onClick={() => {
                  onFileSelected({
                    id: item.id,
                    type: item.type,
                    url: item.smallUrl,
                  });
                }}
              >
                Seleccionar
              </Button>
              {canDelete && <React.Fragment>
                  <Divider />
                  <Button
                    color={theme.colors.primary}
                    width={"40px"}
                    onClick={() => handleDeleteFile(item.id)}
                  >
                    <Icon name={"trash"} style={{ fontSize: 14 }} />
                  </Button>
                </React.Fragment>
              }
              
            </Row>
            <Divider />
          </Container>
        );
      }

      if (item.type === "video") {
        return (
          <Container
            key={`video-${idx}`}
            width={"300px"}
            color={"transparent"}
            style={{
              marginRight:13,
            }}
          >
            <video src={item.smallUrl} controls>
              Your browser does not support the video tag.
            </video>
            <Divider size={10} />
            <Row color='transparent' justify="center">
              <Button
                onClick={() => {
                  onFileSelected({
                    id: item.id,
                    type: item.type,
                    url: item.smallUrl,
                  });
                }}
              >
                Seleccionar
              </Button>
              {canDelete && <React.Fragment>
                  <Divider />
                  <Button
                    color={theme.colors.primary}
                    width={"40px"}
                    onClick={() => handleDeleteFile(item.id)}
                  >
                    <Icon name={"trash"} style={{ fontSize: 14 }} />
                  </Button>
                </React.Fragment>
              }
              
            </Row>
            <Divider />
          </Container>
        );
      }

      return (
        <div style={{position:'relative'}}>
        <File
          key={`file-${idx}`}
          onClick={() =>
            onFileSelected({
              id: item.id,
              type: item.type,
              url: item.smallUrl,
            })
          }
          style={{ backgroundImage: `url('${item.smallUrl}')` }}
        />
        {canDelete && <div style={{position:'absolute',top:10, right: 10}}>
               <Button
                  color={theme.colors.primary}
                  width={"40px"}
                  onClick={() => handleDeleteFile(item.id)}
                >
                  <Icon name={"trash"} style={{ fontSize: 14 }} />
                </Button>
        </div>}
        </div>
      );
    });
  };

  return (
    <Container
      row
      justify={"space-between"}
      wrap
      padding={"20px"}
      style={{
        maxHeight: "48vh",
        overflow: "scroll",
        backgroundColor: colors.grayLight,
      }}
    >
      <Column color="transparent" style={{width:'100%'}}>
        <Row justify="flex-start" wrap="wrap" color="transparent">
         {renderItems()}
        </Row>
      </Column>
    </Container>
  );
};

export { getFilesByType, DisplayFiles, uploadFile, deleteFile, searchFiles, fileUploadSchema };
