import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Icon } from "../../../components";
import { Wrapper, Item, CircleItem } from "./style";
import { store, routes } from "@ranch/config";

const modules = [
  {
    title: "Noticias",
    path: "/dashboard/news",
    icon: "news",
    slug: "news",
  },
  {
    title: "Fuentes de información",
    path: "/dashboard/sources",
    icon: "person",
    slug: "sources",
  },
  /*{
    title: "Super Destacadas",
    path: "/dashboard/superfeatured",
    icon: "news",
    slug: "superfeatured",
  },*/
  {
    title: "Multimedia",
    path: "/dashboard/multimedia",
    icon: "micro",
    slug: "multimedia",
  },
  {
    title: "Usuarios",
    path: "/dashboard/users",
    icon: "person",
    slug: "users",
  },
  {
    title: "Perfiles",
    path: "/dashboard/profiles",
    icon: "profile",
    slug: "profiles",
  },
  {
    title: "Locutores",
    path: "/dashboard/announcers",
    icon: "userGroup",
    slug: "announcers",
  },
  {
    title: "Encuestas",
    path: "/dashboard/polls",
    icon: "poll",
    slug: "polls",
  },
  {
    title: "Programación",
    path: "/dashboard/programs",
    icon: "calendar",
    slug: "programs",
  },
  {
    title: "Notificaciones",
    path: "/dashboard/notifications",
    icon: "notifications2",
    slug: "notifications",
  },
  {
    title: "Pronósticos",
    path: "/dashboard/weather",
    icon: "weather",
    slug: "weather",
  },
  {
    title: "Clima",
    path: "/dashboard/clima",
    icon: "weather",
    slug: "clima",
  },
  /*{
    title: 'Saludos',
    path: '/dashboard/salutations',
    icon: 'salutations',
    slug: 'posts',
  },*/
  {
    title: "Servicio Social",
    path: "/dashboard/posts",
    icon: "callMade",
    slug: "posts",
  },
  {
    title: "Enlaces en vivo",
    path: "/dashboard/live-links",
    icon: "poll",
    slug: "live-links",
  },
];

const Sidebar = () => {
  const isUserAuth = store.getItem("authToken") || false;
  const history = useHistory();
  const permissions = JSON.parse(store.getItem("modules")) || [];

  const handleClickItem = (path) => history.push(path);

  const handleLogout = () => {
    window.localStorage.clear();
    history.replace({ pathname: routes.LOGIN });
  };

  const renderItems = () => {
    const hasAccessTo = modules.filter((item) => {
      const hasPermission = permissions.find(
        (module) => module.slug === item.slug
      );
      if (hasPermission) {
        return hasPermission;
      }
    });

    return (
      <React.Fragment>
        {hasAccessTo.map((item, index) => (
          <Item
            key={`item-${index}`}
            title={`Ver ${item.title}`}
            onClick={() => handleClickItem(item.path)}
          >
            <Icon name={item.icon} style={{ fontSize: 22 }} />
          </Item>
        ))}
        <Item title={"Cerrar Sesión"} onClick={() => handleLogout()}>
          <Icon name={"logout"} />
        </Item>
      </React.Fragment>
    );
  };

  return (
    <Row width={"5%"}>
      {isUserAuth && (
        <Wrapper>
          <CircleItem
            onClick={() => handleClickItem("/dashboard/createNews")}
          />
          {renderItems()}
        </Wrapper>
      )}
    </Row>
  );
};

export default Sidebar;
