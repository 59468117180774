import * as yup from "yup";
import { api } from "@ranch/api";
import { settings } from "@ranch/config";

const postSchema = yup.object().shape({
  title: yup.string().required("Escriba el título."),
  content: yup.string().required("Escriba el contenido."),
  published: yup.number().required("Elija una opción."),
  type: yup.string().required("Elija una categoría."),
});

const getPosts = (page = 0, type = "general", published = 1) => {
  const endpointProps = {
    page,
  };

  if (type && type.length > 0) {
    endpointProps.type = type;
  }
  if (published === 0 || published === 1) {
    endpointProps.published = `${published}`;
  }

  return api
    .get("/posts", { params: endpointProps })
    .then(({ status, data }) => {
      const { posts } = data;
      const totalPages = posts?.total || 0;
      return [null, status, { posts: posts?.data || [] }, totalPages];
    })
    .catch((error) => {
      return [error, null, null, 0];
    });
};

const getPost = (postId) => {
  const url = `/posts/${postId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const createUpdatePost = (post, actionType = "create") => {
  const action = actionType === "create" ? "post" : "put";
  const params = actionType === "create" ? "" : `/${post.id}`;
  const url = `/posts${params}`;

  return api[action](url, post)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const deletePost = (id) =>
  api
    .delete("/posts/" + id)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

export { postSchema, getPost, getPosts, createUpdatePost, deletePost };
