import React, { useReducer, useEffect } from 'react';
import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Icon,
  Button,
} from '@ranch/components';
import { useTheme } from 'styled-components';
import { settings, routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getProfiles } from '@ranch/models/profiles';
import { useHistory } from 'react-router-dom';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const ProfilesScreen = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    profiles: [],
    error: null,
    totalPages: 0,
  });

  const { loading, profiles, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        justify={'space-between'}
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.PROFILE_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const profilesTableColumns = [
    {
      Header: 'Nombre',
      accessor: 'title',
      width: 400,
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  const fetchProfiles = async (updatedPage = 0) => {
    setState({ loading: true });
    const currentPage = updatedPage || page;
    const [error, status, { profiles }, totalPages] = await getProfiles(
      currentPage
    );

    if (error) setState({ error });
    if (status !== 200) setState({ error, profiles });
    if (status === 200 && profiles && profiles.length > 0 && !error) {
      setState({ profiles, error: null });
    }
    if (status === 200 && profiles && profiles.length === 0 && !error) {
      setState({ profiles: [], error: null });
    }
    setState({ loading: false, totalPages });
  };

  useEffect(() => {
    fetchProfiles(page);
  }, [page]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Perfiles</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.PROFILE_ITEM('create'))}
          >
            Crear nuevo perfíl
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table
            columns={profilesTableColumns}
            data={profiles}
            loading={loading}
          />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default ProfilesScreen;
