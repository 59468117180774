import styled from 'styled-components';

const TableStyles = styled.div`
  table {
    font-family: Helvetica;
    border-spacing: 1;
    border-collapse: collapse;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 5px -5px #000;
  }
  table * {
    position: relative;
    font-family: Helvetica;
  }
  table td,
  table th {
    padding-left: 8px;
  }
  table thead {
    border-bottom: 1px solid #f1f1f1;
  }
  table thead tr {
    height: 60px;
    background-color: #ffffff;
  }
  table tbody tr {
    height: 50px;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td,
  table th {
    text-align: left;
  }
  table td.l,
  table th.l {
    text-align: right;
  }
  table td.c,
  table th.c {
    text-align: center;
  }
  table td.r,
  table th.r {
    text-align: center;
  }
  .table100-head th {
    font-family: OpenSans-Regular;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset;
  }
  tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
  }
  tbody tr {
    font-family: OpenSans-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.2;
    font-weight: unset;
  }
  tbody tr:hover {
    color: #555;
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .column1 {
    width: 260px;
    padding-left: 40px;
  }
  .column2 {
    width: 160px;
  }
  .column3 {
    width: 245px;
  }
  .column4 {
    width: 110px;
    text-align: right;
  }
  .column5 {
    width: 170px;
    text-align: right;
  }
  .column6 {
    width: 222px;
    text-align: right;
    padding-right: 62px;
  }
`;

const Table = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableHr = styled.th`
  text-align: left;
`;

const TableHeader = styled.thead`
  text-align: left;
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableRow = styled.tr``;

const TableCell = styled.td``;

export {
  TableHr,
  TableStyles,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
};
