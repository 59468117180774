export const SET_MODAL = 'SET_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const setModal = (dispatch, data) =>
  dispatch({
    type: SET_MODAL,
    data,
  });

export const hideModal = dispatch =>
  dispatch({
    type: HIDE_MODAL,
  });
