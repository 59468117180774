import React, { useReducer, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Button,
  Icon,
  ImageBackground,
} from '@ranch/components';
import { routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getForecasts } from '@ranch/models/weather';
import moment from 'moment';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const WeatherScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    weather: [],
    error: null,
    totalPages: 0,
  });

  const { loading, weather, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchForecast = async () => {
    setState({ loading: true, error: null, weather: [] });
    const [error, status, { forecast }, totalPages] = await getForecasts(page);

    if (status !== 200 || error) {
      notify.show('Ha ocurrido un error.', 'error');
    }

    if (status === 200 && forecast) {
      setState({ weather: forecast, error: null, loading: false, totalPages });
    }
  };

  useEffect(() => {
    fetchForecast();
  }, []);

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        justify={'space-between'}
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.FORECAST_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const weatherColumns = [
    {
      Header: '#',
      accessor: 'image',
      width: 400,
      width: 100,
      Cell: ({ value }) => <ImageBackground src={value} />,
    },
    {
      Header: 'Título',
      accessor: 'title',
      width: 400,
    },
    {
      Header: 'Descripción',
      accessor: 'description',
      width: 400,
    },
    {
      Header: 'Temperaturas',
      width: 400,
      Cell: ({ row }) => {
        const { maxTempC, minTempC, maxTempF, minTempF } = row.original;
        return (
          <>
            <span>
              <Icon
                name="weather"
                style={{
                  fontSize: 25,
                  color: theme.colors.primary,
                }}
              />{' '}
              Máxima: {`${maxTempC}°C, ${maxTempF}°F `}
            </span>
            <br />
            <span>
              <Icon
                name="weather"
                style={{
                  fontSize: 25,
                  color: theme.colors.primary,
                }}
              />{' '}
              Mínima: {`${minTempC}°C, ${minTempF}°F `}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Pronósticos</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.FORECAST_ITEM('new'))}
          >
            Crear Pronóstico
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table columns={weatherColumns} data={weather} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default WeatherScreen;
