import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import { Button, Icon } from '@ranch/components';
import { Backdrop, Modal, Header, Body, Footer, Container } from './styles';
import ModalContext from '@ranch/contexts/ModalContext';
import { hideModal } from '@ranch/contexts/ModalContext/actions';

const ContextModal = () => {
  const {
    state: {
      visibility,
      title,
      children,
      onConfirm,
      onCancel,
      confirmText,
      cancelText,
      useButton,
      afterClosed,
    },
    dispatch,
  } = useContext(ModalContext);

  const handleCloseModal = () => {
    if (afterClosed) {
      afterClosed();
    }
    hideModal(dispatch);
  };

  const theme = useTheme();

  return (
    <Container visibility={visibility}>
      <Backdrop />
      <Modal>
        <Header>
          <h1>{title}</h1>
          <Button
            color={theme.colors.primary}
            width={'40px'}
            onClick={handleCloseModal}
          >
            <span style={{ fontSize: 30, color: theme.colors.white }}>
              &times;
            </span>
          </Button>
        </Header>
        <Body>{children}</Body>
        <Footer />
      </Modal>
    </Container>
  );
};

export default ContextModal;
