import * as yup from 'yup';
import { api } from '@ranch/api';

const userSchema = isCreating =>
  yup.object().shape({
    name: yup.string().required('Escriba el nombre del usuario.'),
    profile_id: yup.array().min(1, 'Seleccione el perfíl del usuario.'),
    email: yup
      .string()
      .email('Email inválido.')
      .required('Escriba el email del usuario.'),
    password: isCreating
      ? yup.string().required('Contraseña requerida.')
      : yup.string(),
  });

const formatUsers = items =>
  items.map(item => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      profile: item?.profile?.title,
    };
  });

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Correo electrónico inválido')
    .required('Este campo es requerido'),
  password: yup.string().required('Este campo es requerido'),
});

const authenticateUser = async ({ email, password, token }) => {
  return api
    .post('/login', {
      email,
      password,
      token,
    })
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => {
      console.log(error);
      return [error, null, null];
    });
};

const getUsers = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/users', { params: endpointProps })
    .then(({ status, data }) => {
      const users = formatUsers(data.users);
      const totalPages = data.totalItems || 0;
      return [null, status, { users }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const getUser = userId => {
  const url = `/users/${userId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const createUpdateUser = (user, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${user.id}`;
  const url = `/users${params}`;

  return api[action](url, user)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

export {
  loginSchema,
  authenticateUser,
  getUsers,
  getUser,
  userSchema,
  createUpdateUser,
};
