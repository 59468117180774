import React, { createContext, useMemo, useReducer } from 'react';

import { SET_MODAL, HIDE_MODAL } from './actions';

const ModalContext = createContext('ranch-modal-context');

const initialState = {
  title: '',
  visibility: false,
  children: null,
  onConfirm: () => {},
  onCancel: () => {},
  cancelText: '',
  confirmText: '',
  useButton: false,
  extraProps: {},
  afterClosed: () => {},
};

const reduceMapper = {
  [SET_MODAL]: (state, modal) => ({ ...state, visibility: true, ...modal }),
  [HIDE_MODAL]: state => ({ ...state, visibility: false }),
};

const modalReducer = (state, { type, data }) =>
  reduceMapper[type] ? reduceMapper[type](state, data) : state;

export const ModalReducerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
