import React from "react";
import { Route } from "react-router-dom";
import { store } from "@ranch/config";
import { LoginScreen } from "@ranch/routes";

const PrivateRoute = ({ component, ...options }) => {
  const isUserAuth = store.getItem("authToken") || null;
  const finalComponent = isUserAuth ? component : LoginScreen;

  return <Route {...options} component={finalComponent} />;
};

export default PrivateRoute;
