import React, { useEffect, useReducer } from 'react';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  PageHeader,
  Dropdown,
  TextInput,
  Divider,
  Button,
  Column,
  Error,
} from '@ranch/components';
import { getProfiles } from '@ranch/models/profiles';
import { getUser, userSchema, createUpdateUser } from '@ranch/models/users';
import { notify } from 'react-notify-toast';
import { routes } from '@ranch/config';

const UserDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    profiles: [],
    isLoadingProfiles: false,
    saving: false,
  });

  const { isLoadingProfiles, profiles, saving } = state;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(userSchema(id && id > 0 ? false : true)),
  });

  const modulesWatcher = watch('profile');

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onSubmit = async data => {
    setState({ saving: true });

    const userData = {
      name: data.name,
      email: data.email,
      password: data.password,
      profile_id: data.profile.value,
    };

    const submitType = id && id > 0 ? 'update' : 'create';

    if (id && id > 0) {
      userData.id = id;
    }

    const [error, status, { message, errors }] = await createUpdateUser(
      userData,
      submitType
    );

    if (error) {
      notify.show(error, 'error');
    }

    if (status === 200) {
      if (message == 'USER_NOT_CREATED' && errors) {
        let formattedErrors = '';
        Object.keys(errors).forEach(item => {
          return (formattedErrors += `${errors[item]} | `);
        });
        notify.show(formattedErrors, 'error');
      }

      if (message === 'UPDATED_USER') {
        notify.show('Usuario actualizado correctamente.', 'success');
      }

      if (message === 'CREATED') {
        notify.show('Usuario creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.USERS);
        }, 1500);
      }
    }

    if (status !== 200) {
      notify.show('Error al actualizar usuario.', 'error');
    }

    setState({ saving: false });
  };

  const fetchUser = async id => {
    register({ name: 'profile', value: [] });
    setState({ isLoadingProfiles: true });
    const [error, status, { profiles }] = await getProfiles();

    const formatProfiles = profiles.map(item => {
      return {
        label: item.title,
        value: item.id,
      };
    });

    setState({ isLoadingProfiles: false, profiles: formatProfiles });

    if (id && Number(id)) {
      const [errorUser, statusUser, user] = await getUser(id);
      if (statusUser === 200 && user && profiles) {
        setValue('name', user.name);
        setValue('email', user.email);

        let findSelectedProfile = {};
        profiles.forEach(item => {
          if (user.profile_id === item.id) {
            findSelectedProfile = {
              label: item.title,
              value: item.id,
            };
          }
        });
        setValue('profile', findSelectedProfile);
      }
    }
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Detalles del Usuario</PageHeader>
        </Container>
        <Column padding={'15px'} flex={0}>
          <TextInput
            name={'name'}
            label={'Nombre'}
            ref={register}
            type={'text'}
            error={hasError('name')}
          />
          <Divider size={20} />
          <TextInput
            name={'email'}
            label={'Email'}
            ref={register}
            type={'email'}
            error={hasError('email')}
          />
          <Divider size={20} />
          <TextInput
            name={'password'}
            label={'Contraseña'}
            ref={register}
            type={'text'}
            error={hasError('password')}
          />
          <Divider size={20} />
          <Dropdown
            title={'Perfíl'}
            options={profiles}
            name={'profile'}
            loading={isLoadingProfiles}
            value={!isLoadingProfiles && getValues('profile')}
            onChange={e => {
              setValue('profile', e);
            }}
            style={{ flex: 0 }}
          />
          {hasError('profile') && <Error>{hasError('profile')}</Error>}
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Usuario
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default UserDetails;
