import React, { useReducer, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import {
  Container,
  PageHeader,
  TextInput,
  Divider,
  Button,
  Column,
} from '@ranch/components';
import {
  programsSchema,
  getProgram,
  createUpdateProgram,
} from '@ranch/models/programs';
import { notify } from 'react-notify-toast';
import { settings, routes } from '@ranch/config';

const ProgramsDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
  });

  const { saving } = state;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(programsSchema),
  });

  const onSubmit = async data => {
    setState({ saving: true });
    const submitType = id && id > 0 ? 'update' : 'create';

    const program = {
      ...data,
    };

    if (id && id > 0) {
      program.id = id;
    }

    const [error, status, { message }] = await createUpdateProgram(
      program,
      submitType
    );

    if (status === 200) {
      if (message === 'UPDATED_PROGRAM') {
        notify.show('Programación actualizado correctamente.', 'success');
      }

      if (message === 'CREATED_PROGRAM') {
        notify.show('Programación creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.PROGRAMS);
        }, 1500);
      }
    }

    if (status === 202) {
      if (message === 'ERROR_UPDATING_PROGRAM') {
        notify.show('Error al actualizar programación.', 'error');
      }

      if (message === 'ERROR_CREATING_PROGRAM') {
        notify.show('Error al crear programación.', 'error');
      }
    }

    setState({ saving: false });
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const initialize = async () => {
    if (id && Number(id)) {
      const [error, status, data] = await getProgram(id);

      if (status === 200 && data) {
        setValue('title', data.title);
        setValue('schedule', data.schedule);
        setValue('order_shown', data.order_shown);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Detalles de la Programación</PageHeader>
        </Container>
        <Column padding={'15px'}>
          <TextInput
            name={'title'}
            label={'Título'}
            ref={register}
            type={'text'}
            error={hasError('title')}
          />
          <Divider size={20} />
          <TextInput
            name={'schedule'}
            label={'Horarios'}
            ref={register}
            type={'text'}
            error={hasError('schedule')}
          />
          <Divider size={20} />
          <TextInput
            name={'order_shown'}
            label={'Orden de aparición'}
            ref={register}
            type={'text'}
            error={hasError('order_shown')}
          />
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Programación
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default ProgramsDetails;
