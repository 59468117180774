import styled from 'styled-components';

const Error = styled.p`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  padding: 0;
  margin: 5px 0 0 0;
`;

export default Error;
