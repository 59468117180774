import React, { useReducer, useEffect } from 'react';
import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Icon,
  Button,
  ImageBackground,
} from '@ranch/components';
import { useTheme } from 'styled-components';
import { settings, routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getAnnouncers } from '@ranch/models/announcers';
import { useHistory } from 'react-router-dom';
import { deleteAnnouncer } from '../models/announcers';
import { notify } from 'react-notify-toast';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const AnnouncersScreen = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    announcers: [],
    error: null,
    totalPages: 0,
  });

  const { loading, announcers, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const handleDeleteAnnouncer = async (announcerId) => {
    if(announcerId){
      const confirm = window.confirm('¿Deseas eliminar permanentemente este locutor?')

      if(confirm){
        const [error, status, data] = await deleteAnnouncer(announcerId);
        if(error || status !== 200){
          notify.show('Error al borrar el locutor','error')
        }else{
          notify.show('Locutor borrado correctamente','success')
          setTimeout(() => {
            window.location.reload();
          },2000)
        }
      }
    }
  } 

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.ANNOUNCER_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        <div style={{width:50}} />
        <div onClick={() => handleDeleteAnnouncer(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const announcersTableColumns = [
    {
      Header: '#',
      accessor: 'image',
      Cell: cell => (
        <ImageBackground size={90} src={cell?.row?.original?.image || ''} />
      ),
    },
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 80,
    },
    {
      Header: 'Programas',
      accessor: 'programs',
    },
    {
      Header: 'Activo',
      accessor: 'published',
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  const fetchAnnouncers = async (updatedPage = 0) => {
    setState({ loading: true });
    const currentPage = updatedPage || page;
    const [error, status, { announcers }, totalPages] = await getAnnouncers(
      currentPage
    );

    if (error) setState({ error });
    if (status !== 200) setState({ error, announcers });
    if (status === 200 && announcers && announcers.length > 0 && !error) {
      setState({ announcers, error: null });
    }
    if (status === 200 && announcers && announcers.length === 0 && !error) {
      setState({ announcers: [], error: null });
    }
    setState({ loading: false, totalPages });
  };

  useEffect(() => {
    fetchAnnouncers(page);
  }, [page]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Locutores</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.ANNOUNCER_ITEM('create'))}
          >
            Crear nuevo locutor
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table
            columns={announcersTableColumns}
            data={announcers}
            loading={loading}
          />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default AnnouncersScreen;
