import styled, { css } from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 8px;
`;

const Radio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.purple};

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.white};
      }
    `};
`;

export { Row, Radio };
