import styled, { css } from 'styled-components';

const Button = styled.button`
  border-radius: 20px;
  height: 40px;
  background-color: ${({ theme, background }) =>
    background ? background : theme.colors.primary};
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  font-size: 14px;
  border: 0;
  font-weight: bold;
  box-shadow: 0 0 3px 1px ${({ theme }) => theme.colors.grayLight};
  cursor:pointer;
  transition:all 300ms ease;

  &:hover{
    opacity:0.85;
  }

  ${({ normal }) =>
    normal &&
    css`
      box-shadow: none;
      border-radius: 0;
      background-color: transparent !important;
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ disabled }) => disabled && `opacity:0.25;`};
`;

export default Button;
