import React, { useReducer, useEffect } from 'react';
import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Icon,
  Button,
} from '@ranch/components';
import { useTheme } from 'styled-components';
import { settings, routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getUsers } from '@ranch/models/users';
import { useHistory } from 'react-router-dom';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const UsersScreen = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    users: [],
    error: null,
    totalPages: 0,
  });

  const { loading, users, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        justify={'space-between'}
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.USER_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const usersTableColumns = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Perfíl',
      accessor: 'profile',
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  const fetchUsers = async (updatedPage = 0) => {
    setState({ loading: true });
    const currentPage = updatedPage || page;
    const [error, status, { users }, totalPages] = await getUsers(currentPage);

    if (error) setState({ error });
    if (status !== 200) setState({ error, users });
    if (status === 200 && users && users.length > 0 && !error) {
      setState({ users, error: null });
    }
    if (status === 200 && users && users.length === 0 && !error) {
      setState({ users: [], error: null });
    }
    setState({ loading: false, totalPages });
  };

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Perfiles</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.USER_ITEM('create'))}
          >
            Crear nuevo usuario
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table columns={usersTableColumns} data={users} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default UsersScreen;
