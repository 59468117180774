import React, { useReducer, useEffect } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { notify } from "react-notify-toast";

import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Button,
  Icon,
  ImageBackground,
} from "@ranch/components";
import { routes } from "@ranch/config";
import useQueryParams from "@ranch/hooks/useQueryParams";
import { _getSources, deleteSource } from "@ranch/models/source";

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const SourceScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    sources: [],
    error: null,
    totalPages: 0,
  });

  const { loading, sources, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchSources = async (page = 1) => {
    setState({ loading: true, error: null, sources: [] });
    const [error, status, data, totalPages] = await _getSources(page);

    if (status !== 200 || error) {
      notify.show("Ha ocurrido un error.", "error");
    }

    if (status === 200 && data) {
      setState({
        sources: data.sources,
        error: null,
        loading: false,
        totalPages,
      });
    }
  };

  const handleDeleteSource = async (sourceId) => {
    if (sourceId) {
      const confirm = window.confirm(
        "¿Deseas eliminar permanentement esta fuente?"
      );

      if (confirm) {
        const [error, status, data] = await deleteSource(sourceId);
        if (error || status !== 200) {
          notify.show("Error al borrar la fuente", "error");
        } else {
          notify.show("Fuente borrada correctamente", "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    }
  };

  useEffect(() => {
    fetchSources(page);
  }, [page]);

  const renderActions = (cellData) => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={"transparent"}
        row
        justify={"space-between"}
        padding={"0px 15px 0 0"}
      >
        <div onClick={() => history.push(routes.SOURCE_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        <div onClick={() => handleDeleteSource(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const sourceTableColumns = [
    {
      Header: "Título",
      accessor: "title",
      width: 250,
      Cell: ({ value, cell }) => (
        <div
          onClick={() => history.push(routes.SOURCE_ITEM(cell.row.original.id))}
        >
          <span>{value}</span>
        </div>
      ),
    },
    {
      Header: "Acciones",
      accessor: "",
      width: 150,
      Cell: (cell) => renderActions(cell),
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container row padding={"15px"} justify={"space-between"} flex={0}>
          <PageHeader>Fuentes</PageHeader>
          <Button
            width={"160px"}
            onClick={() => history.push(routes.SOURCE_ITEM("new"))}
          >
            Crear Fuente
          </Button>
        </Container>
        <Container padding={"15px"}>
          <Table
            columns={sourceTableColumns}
            data={sources}
            loading={loading}
          />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={(page) => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default SourceScreen;
