import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

/**
 *
 * Why we are not using 'use-query-params' as it is?
 *
 * 1. "Undefined query values": it returns keys with an undefined value. We need to receive only the keys with non-undefined values;
 *
 * 2. "Default UrlUpdateType": it uses 'replaceIn' as default UrlUpdateType making the back button not work. We re-default to 'pushIn', keeping the browser history.
 *
 */

const configTypes = query => {
  return Object.keys(query).reduce((acc, key) => {
    const value = query[key];
    return {
      ...acc,
      [key]: Number.isNaN(parseInt(value, 10)) ? StringParam : NumberParam,
    };
  }, {});
};

const cleanUpQuery = query => {
  const hasOnlyNumbers = /^[0-9]+.?[0-9]+$/;

  return Object.keys(query).reduce((acc, key) => {
    const value = query[key];
    if (typeof value === 'undefined') return acc;
    return {
      ...acc,
      [key]: hasOnlyNumbers.test(value) ? Number(value) : value,
    };
  }, {});
};

const useQueryParameters = defaultQuery => {
  const [query, setQuery] = useQueryParams(configTypes(defaultQuery));

  const setQueryWithHistory = obj => setQuery(obj, 'pushIn');

  return [cleanUpQuery(query), setQueryWithHistory];
};

export default useQueryParameters;
