import React from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.span`
  font-family: RancheritaIcons !important;
  font-size: 25px;
  color: white;
`;

const Icon = ({ name, style }) => {
  const theme = useTheme();
  return <Wrapper style={style}>{theme.icons[name]}</Wrapper>;
};

export default Icon;
