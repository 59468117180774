import React, { useEffect, useReducer } from 'react';
import { Backdrop, Body, Button, ButtonsWrap, Description, Image, Title, Wrapper } from './styles';

const DeviceNotification = ({ title, content, image, type }) => {
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    title,
    content,
    image,
    type
  });

  useEffect(() => {
    setState({ title, content, image, type });
  }, [title, content, image, type]);

  return (
    <Wrapper>
      <Backdrop>
        <Body>
          <Title>{state.title}</Title>
          {state.image && <Image src={state.image} />}
          <Description>{content}</Description>
          {state.type && <ButtonsWrap>
            <Button>Cerrar</Button>
            <Button onClick={() => {
              if(type === 'link'){
                window.open(content)
                return
              }
            }}>{type === 'link' ? 'Ir al enlance' : 'OK'}</Button>
          </ButtonsWrap>}
        </Body>
      </Backdrop>
    </Wrapper>
  );
};

export default DeviceNotification;
