import styled from 'styled-components';
import { settings } from '@ranch/config';

const imagesPath = settings.isLocal()
  ? 'http://localhost/uploads/images/'
  : settings.imagesUrl;

const ImageBackground = styled.div`
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;

  ${({ width }) => width && `width:${width}px`};
  ${({ height }) => height && `height:${height}px`};

  ${({ size }) =>
    size &&
    `
    width : ${size}px;
    height : ${size}px;
  `};

  ${({ src }) => src && `background-image:url('${src}');`};

  ${({ large }) =>
    large &&
    `
    width : 250px;
    height : 250px;
  `};
`;

export default ImageBackground;
