import React from 'react';
import Notifications from 'react-notify-toast';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { theme, fonts } from './config';
import IconFont from './assets/fonts/RancheritaIcons.ttf';
import { ModalReducerProvider } from '@ranch/contexts/ModalContext';
import { Modal } from '@ranch/components';
import { SwitchScreen, Dashboard } from './routes';

const GlobalStyle = createGlobalStyle`

  ${fonts.outputFonts()};

  @font-face {
    font-family: RancheritaIcons;
    src: url(${IconFont});
  }

  body{
    padding:0;
    margin:0;
  }

  .rdw-editor-wrapper{
    overflow:hidden;
  }

  .react-date-picker__wrapper{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 40px;
    color: #323232;
    outline: none;
    padding: 0 15px;
    font-size: 14px;
  }

  #root {
    display:flex;
    width:100%;
    height:100vh;
    flex-direction:column;
    flex:1;
  }
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ModalReducerProvider>
            <Notifications options={{ zIndex: 9999999 }} />
            <Route exact path={'/'} component={SwitchScreen} />
            <Route path={'/dashboard'} component={Dashboard} />
            <Modal />
          </ModalReducerProvider>
        </QueryParamProvider>
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
