import { api } from '@ranch/api';
import 'moment/locale/es';
import moment from 'moment';
import * as yup from 'yup';

moment().locale('es');

const multimediaFilterSchema = yup.object().shape({
  title: yup.string(),
});

const multimediaDetailsSchema = yup.object().shape({
  title: yup.string().required('Escriba el título.')
});

const formatItems = items => items.map(item => {
    return {
      id: item.id,
      title: item.title,
      createdAt: moment(item.created_at).format('LLL'),
      audios : item.audios,
      video : item.video
    };
  });

const getMultimedia = (
  page = 0,
  keyword = ''
) => {
  const endpointProps = {
    page,
  };

  if (keyword && keyword.length > 0) {
    endpointProps.keyword = keyword;
  }

  return api
    .get('/multimedia', { params: endpointProps })
    .then(({ status, data }) => {
      const multimedia = formatItems(data.multimedia);
      const totalPages = data.totalItems || 0;
      return [null, status, { multimedia }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const getMultimediaDetails = id => {
  return api
    .get(`/multimedia/${id}`)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => {
      return [error, null, null];
    });
};

const createUpdateMultimedia = (multimedia, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${multimedia.id}`;

  const url = `/multimedia${params}`;

  return api[action](url, multimedia)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const deleteMultimedia = id => api
.delete('/multimedia/'+id)
.then(({ status, data }) => [null, status, data])
.catch(error => [error, null, null]);

export {
  getMultimedia,
  getMultimediaDetails,
  multimediaFilterSchema,
  multimediaDetailsSchema,
  createUpdateMultimedia,
  deleteMultimedia
};
