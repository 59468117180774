import React, { useCallback, useEffect, useReducer } from 'react';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  PageHeader,
  TextInput,
  Divider,
  Button,
  Column,
  Checkbox,
  Text
} from '@ranch/components';
import { notify } from 'react-notify-toast';
import { routes } from '@ranch/config';
import { createUpdateLiveLink, getLiveLink, liveLinkSchema } from '../models/liveLinks';

const UserDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const params = useParams();
  const { id } = params;
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    saving: false,
    item : null
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues
  } = useForm({
    resolver: yupResolver(liveLinkSchema()),
  });

  watch('is_published')

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onSubmit = async data => {
    setState({ saving: true });

    const submitType = id && id > 0 ? 'update' : 'create';

    if (id && id > 0) {
      data.id = id;
    }

    const [error, status, { message, errors }] = await createUpdateLiveLink(
      data,
      submitType
    );

    if (error) {
      notify.show(error, 'error');
    }

    if (status === 200) {
      if (message === 'LIVE_LINK_NOT_CREATED' && errors) {
        let formattedErrors = '';
        Object.keys(errors).forEach(item => {
          return (formattedErrors += `${errors[item]} | `);
        });
        notify.show(formattedErrors, 'error');
      }

      if (message === 'UPDATED_LIVE_LINK') {
        notify.show('Enlace actualizado correctamente.', 'success');
      }

      if (message === 'CREATED') {
        notify.show('Enlace creado correctamente.', 'success');
        setTimeout(() => {
          history.push(routes.LIVE_LINKS);
        }, 1500);
      }
    }

    if (status !== 200) {
      notify.show('Error al actualizar enlace.', 'error');
    }

    setState({ saving: false });
  };

  const handleValueChange = (name,evt) => setValue(name, evt.target.checked)

  const fetchLiveLink = useCallback(async () => {
    // DC: prevent unnecessary request
    if(id === 'new') return

    setState({ loading: true });
    const [error, status, liveLink] = await getLiveLink(id);

    setState({ loading: false, item: liveLink });

    setValue('name',liveLink.name)
    setValue('url',liveLink.url)
    setValue('is_published',liveLink.is_published)
    setValue('notification_title',liveLink.notification_title)

  },[id, setValue])

  useEffect(() => {
    fetchLiveLink();
  }, [fetchLiveLink]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container padding={'15px'} justify={'space-between'}>
          <PageHeader>Enlace En Vivo</PageHeader>
        </Container>
        <Column padding={'15px'} flex={0}>
        <TextInput
            name={'name'}
            label={'Nombre'}
            ref={register}
            type={'text'}
            error={hasError('name')}
          />
          <Divider size={20} />
          <TextInput
            name={'url'}
            label={'Enlace (facebook, youtube)'}
            ref={register}
            type={'text'}
            error={hasError('url')}
          />
          <Divider size={20} />
          <TextInput
            name={'notification_title'}
            label={'Título de la notification'}
            ref={register}
            type={'text'}
          />
          <Divider size={20} />
          <label style={{textAlign:'left'}}>
            <Checkbox
              checked={getValues('is_published')}
              onChange={(evt) => handleValueChange('is_published', evt)}
              ref={register}
              name={'is_published'}
            />
            <Text color='#323232' size={14} style={{ marginLeft: 8 }}>¿Está visible?</Text>
          </label>
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={state.saving}>
            Guardar Enlace
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default UserDetails;
