import styled from 'styled-components';

const Wrapper = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 4px;
  height: 250px;
  color: ${({ theme }) => theme.colors.black};
  border-color: hsl(0, 0%, 80%);
  outline: none;
  font-size: 16px;
  font-family: Arial;
  padding: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  display: block;
  padding-bottom: 6px;
`;

const Error = styled.p`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  padding: 0;
  margin: 5px 0 0 0;
`;

export { Wrapper, Label, Error };
