import styled from 'styled-components'

const Container = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding-top:4rem;
`

const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    flex:1;
    align-items:center;
`

const Image = styled.div`
    border-radius:4px;
    background-color:#dddddd;
    transition:all 300ms ease;
    width:100px;
    height:100px;
    margin-right:0.75rem;
    position:relative;
    
    &:hover{
        opacity:0.5;
    }

    ${({ src }) => src && `
        background-image : url(${src});
        background-size: cover;
        background-position:center;
        background-repeat:no-repeat;
    `}
`

const DownloadWrapper = styled.div`
    position:absolute;
    bottom:10px;
    right:10px;
    z-index:2;
    cursor:pointer;
`

export {
    Wrapper,
    Image,
    Container,
    DownloadWrapper
}