import React, { useEffect, useReducer, useContext } from "react";
import { useTheme } from "styled-components";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  PageHeader,
  Dropdown,
  TextInput,
  Divider,
  Button,
  Column,
  FileManager,
  ImageBackground,
  Text,
} from "@ranch/components";
import ModalContext from "@ranch/contexts/ModalContext";
import { setModal, hideModal } from "@ranch/contexts/ModalContext/actions";
import {
  sourceSchema,
  createUpdateSource,
  getSource,
} from "@ranch/models/source";
import { notify } from "react-notify-toast";
import { routes, settings } from "@ranch/config";

const SourceDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
    featuredImage: "",
  });

  const { saving, featuredImage } = state;

  const { register, handleSubmit, errors, setValue, getValues, watch } =
    useForm({
      resolver: yupResolver(sourceSchema),
    });

  watch("type", "");

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const hasError = (field) => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onSubmit = async (data) => {
    setState({ saving: true });
    const submitType = id && id > 0 ? "update" : "create";

    const sourceData = {
      ...data,
    };

    if (id && id > 0) {
      sourceData.id = id;
    }

    const [error, status, { message }] = await createUpdateSource(
      sourceData,
      submitType
    );

    if (status === 200) {
      if (message === "UPDATED_SOURCE") {
        notify.show("Fuente actualizada correctamente.", "success");
      }

      if (message === "CREATED_SOURCE") {
        notify.show("Fuente creada correctamente.", "success");
        setTimeout(() => {
          history.push(routes.SOURCES);
        }, 250);
      }
    }

    if (status === 202) {
      if (message === "ERROR_UPDATING_SOURCE") {
        notify.show("Error al actualizar fuente.", "error");
      }

      if (message === "ERROR_CREATING_SOURCE") {
        notify.show("Error al crear fuente.", "error");
      }
    }

    setState({ saving: false });
  };

  const fetchSource = async (id) => {
    register({ name: "file_id", value: 0 });
    register({ name: "type" });

    if (id && Number(id)) {
      const [errorSource, statusSource, source] = await getSource(id);
      if (statusSource === 200 && source) {
        setValue("file_id", source.file_id);
        setState({
          featuredImage: `${settings.imagesUrl}${source.image.small_url}`,
        });
        setValue("title", source.title);
        setValue("description", source.description);
        setValue("email", source.email);
        setValue("web_site", source.web_site);
        setValue("type", source.type);
        setValue("observations", source.observations);
      }
    }
  };

  const handleSelectedFile = (file) => {
    setState({ featuredImage: file.url });
    setTimeout(() => {
      setValue("file_id", file.id);
      hideModal(modalDispatch);
    }, 250);
  };

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: "Administrador de archivos",
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  useEffect(() => {
    fetchSource(id);
  }, [id]);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"}>
          <PageHeader>Detalles de la fuente</PageHeader>
        </Container>
        <Column padding={"15px"}>
          <Container width={"40%"} flex={0}>
            {featuredImage && <ImageBackground src={featuredImage} large />}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>
              Imagen de la fuente
            </Button>
            <Divider size={10} />
            {errors["file_id"] && (
              <Text size={12} color={theme.colors.primary}>
                {errors["file_id"].message}
              </Text>
            )}
          </Container>
          <Divider size={20} />
          <Container flex={0}>
            <Container row>
              <Container width={"50%"}>
                <TextInput
                  name={"title"}
                  label={"Título"}
                  ref={register}
                  type={"text"}
                  error={hasError("title")}
                />
              </Container>
              <Divider size={20} />
              <Container width={"50%"}>
                <TextInput
                  name={"description"}
                  label={"Descripción"}
                  ref={register}
                  type={"text"}
                  error={hasError("description")}
                />
              </Container>
            </Container>
          </Container>
          <Divider size={20} />
          <Container flex={0}>
            <Container row flex={0}>
              <Container width={"50%"}>
                <TextInput
                  name={"email"}
                  label={"Email"}
                  ref={register}
                  type={"text"}
                  error={hasError("email")}
                />
              </Container>
              <Divider size={20} />
              <Container width={"50%"}>
                <TextInput
                  name={"web_site"}
                  label={"Sitio Web"}
                  ref={register}
                  type={"text"}
                  error={hasError("web_site")}
                />
              </Container>
            </Container>
          </Container>
          <Divider size={20} />
          <Container flex={0}>
            <Container row flex={0}>
              <Container width={"50%"}>
                <Dropdown
                  title={"Tipo de fuente"}
                  options={settings.sourceType}
                  name={"type"}
                  defaultValue={settings.sourceType.find(
                    (item) => item.value === "interna"
                  )}
                  value={settings.sourceType.find(
                    (item) => item.value === getValues("type")
                  )}
                  onChange={(e) => setValue("type", e.value)}
                  style={{ flex: 0 }}
                />
              </Container>
              <Divider size={20} />
              <Container width={"50%"}>
                <TextInput
                  name={"observations"}
                  label={"Observaciones"}
                  ref={register}
                  type={"text"}
                  error={hasError("observations")}
                />
              </Container>
            </Container>
          </Container>
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Fuente
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default SourceDetails;
