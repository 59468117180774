import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: #480b0b;
`;

const Backdrop = styled.div`
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.3);
  z-index: 1;
`;

const Body = styled.div`
  z-index: 2;
  margin: 16px auto;
  width: 85%;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  display:flex;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 20px;
  color: #000;
  font-weight: bold;
  display:block;
  margin-bottom: 16px;
`

const Image = styled.image`
  width: 100%;
  height: 240px;
`

const Description = styled.span`
  display: block;
  margin-top: 16px;
  font-size: 14px;
  color: #000;
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-end;
  margin-top: 16px;
`

const Button = styled.div`
  font-size: 16px;
  color: blue;
  padding: 0 16px;
  cursor:pointer;
`

export { Wrapper, Backdrop, Body, Title, Image, Description, Button, ButtonsWrap };
