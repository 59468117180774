import React from 'react';
import { useTable } from 'react-table';
import { Container, ActivityIndicator } from '@ranch/components';
import {
  TableStyles,
  Table as TableWrap,
  TableHr,
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
} from './styles';

const Table = ({ columns, data, loading, error }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const Header = () => {
    return (
      <TableHeader>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableHr {...column.getHeaderProps()} width={column.width}>
                {column.render('Header')}
              </TableHr>
            ))}
          </TableRow>
        ))}
      </TableHeader>
    );
  };

  const Body = () => {
    if (!loading) {
      return (
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      );
    }

    return null;
  };

  const placeholder = () => {
    if (loading) {
      return (
        <Container>
          <ActivityIndicator />
        </Container>
      );
    }

    if (error) {
      return <p>Ha ocurrido un error {error}</p>;
    }

    if(data.length === 0){
      return <Container>
        <p>Sin resultados.</p>
      </Container>
    }

    return null;
  };

  return (
    <React.Fragment>
      <TableStyles>
        <TableWrap {...getTableProps()}>
          <Header />
          <Body />
        </TableWrap>
      </TableStyles>
      {placeholder()}
    </React.Fragment>
  );
};

Table.defaultProps = {
  columns: [],
  data: [],
};

export default Table;
