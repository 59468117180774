import React from 'react';
import { Container, Header } from './styles';

const PageHeader = ({ children }) => (
  <Container>
    <Header>{children}</Header>
  </Container>
);

export default PageHeader;
