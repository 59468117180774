import React, { useEffect, useReducer, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Container,
  PageHeader,
  TextInput,
  Column,
  Divider,
  Dropdown,
  FileManager,
  Button,
  FileSelector,
  ImageBackground,
  TagsInput,
  Text,
  ImageDownloader,
  AudioSelector,
} from "@ranch/components";
import ModalContext from "@ranch/contexts/ModalContext";
import { setModal, hideModal } from "@ranch/contexts/ModalContext/actions";
import { settings, store } from "@ranch/config";
import {
  getNewsDetails,
  newsDetailsSchema,
  createUpdateNote,
} from "@ranch/models/news";
import { getSources } from "@ranch/models/source";
import { getCategories } from "@ranch/models/categories";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { notify } from "react-notify-toast";
import moment from "moment";

//https://contactmentor.com/react-drag-drop-list/

const NewsDetails = () => {
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const history = useHistory();

  const modules = JSON.parse(store.getItem("modules"));
  const canPublishNote = !!modules.find((item) => item.slug === "publish_new");

  const _handleBack = () => history.goBack();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    categories: [],
    sources: [],
    isLoadingCategories: false,
    isLoadingSources: false,
    writtenBy: "",
    featuredImage: null,
  });

  const {
    categories,
    sources,
    isLoadingCategories,
    isLoadingSources,
    writtenBy,
    featuredImage,
  } = state;

  const [contentValue, setContentValue] = React.useState(
    EditorState.createEmpty()
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { register, handleSubmit, errors, setValue, getValues, watch } =
    useForm({
      shouldUnregister: false,
      resolver: yupResolver(newsDetailsSchema),
    });

  watch("isPublished", 0);
  watch("in_last_news", 0);
  watch("isFeatured", 0);
  watch("categoryId", 0);
  watch("source", "");
  watch("newCategoryId", 0);
  watch("featured_image_id", 0);

  const initialize = useCallback(() => {
    register({ name: "categoryId" });
    register({ name: "newCategoryId" });
    register({ name: "source" });
    register({ name: "in_last_news", value: 0 });
    register({ name: "isPublished", value: 0 });
    register({ name: "isFeatured", value: 0 });
    register({ name: "sendNotification", value: "" });
    register({ name: "description" });
    register({ name: "tags", value: [] });
    register({ name: "audios", value: [] });
  },[register]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onSubmit = async (data) => {
    let submitType = id ? "update" : "create";
    let submitMessageType = id ? "actualizar" : "crear";
    let submitSuccessMessage = id ? "actualizada" : "creada";

    const isAboutToClone = window.location.pathname.includes("cloneNews");

    if (isAboutToClone) {
      submitType = "create";
      submitMessageType = "clonar";
      submitSuccessMessage = "clonada";
    }

    if (id && !isAboutToClone) {
      data.id = id;
    }

    const formattedNewsDetails = {
      is_published: data.isPublished || 0,
      is_featured: data.isFeatured || 0,
      is_super_featured: data.isSuperFeatured || 0,
      super_featured_ends_at: data.superFeaturedEnds || null,
      big_image_id: 0, // fix this if we need to implement super featured image
      category_id: data.categoryId,
      note_id: data.noteId,
      ...data,
    };

    if (data.isSuperFeatured === 1 && data.superFeaturedEnds.length > 0) {
      //reset fields
      formattedNewsDetails.is_featured = 0;
    }

    if (data.isSuperFeatured === 1 && data.superFeaturedEnds.length == 0) {
      notify.show(
        "Las noticias super destacadas necesitan de una fecha de termino",
        "warning"
      );
      return;
    }

    if (data.source === 0) {
      notify.show("Seleccione la fuente de información", "warning");
      return;
    }

    const [error, status, newsCreateUpdateResponse] = await createUpdateNote(
      formattedNewsDetails,
      submitType
    );

    if (status === 200) {
      if (error) {
        notify.show("Error al " + submitMessageType + " la nota.", "error");
      }
      if (newsCreateUpdateResponse) {
        notify.show(
          "Noticia " + submitSuccessMessage + " correctamente.",
          "success"
        );

        if (submitType === "create") {
          history.push("/dashboard/news");
        }
      }
    }
  };


  const findNewsDetails = useCallback(async (id) => {
    register({ name: "tags", value: [] });
    register({ name: "audios", value: [] });
    if (id) {
      const [error, status, data] = await getNewsDetails(id);

      if (status === 200 && data) {
        setValue("title", data.title);
        setValue("featured_image_id", data.featured_image_id || 0);

        if (data.image) {
          setState({
            featuredImage: `${settings.imagesUrl}${data.image.small_url}`,
          });
        }

        let formattedAudios = [];
        if (data.audios && data.audios.length > 0) {
          formattedAudios = data.audios.map((item) => {
            return {
              fileId: item.id,
              url: `${settings.imagesUrl}${item.small_url}`,
            };
          });
        }
        setValue("audios", formattedAudios);

        let formattedTags = [];
        if (data.tags && data.tags.length > 0) {
          formattedTags = data.tags.map((item) => item.title);
        }
        setValue("tags", formattedTags);

        //const superfeatureDetails = data.super_featured_details.length > 0 ? data.super_featured_details[0] : null;
        setValue("source", data.source ? data.source.id : 0);
        setValue("isSuperFeatured", data.is_super_featured || 0);
        setValue("in_last_news", data.in_last_news || 0);
        setValue("short_title", data.short_title || "");
        setValue("categoryId", data.category_id || "0");
        setValue("newCategoryId", data.category_to_move || "0");

        const superFeaturedEndsAt = data.super_featured_ends_at
          ? moment(data.super_featured_ends_at).format("YYYY-MM-DDTHH:mm")
          : "";

        setValue("superFeaturedEnds", superFeaturedEndsAt);
        setValue("noteId", data.note_id);
        setValue("isPublished", data.is_published || "0");
        setValue("isFeatured", data.is_featured || "0");
        setValue("sendNotification", "0");

        const blocksFromHtml = htmlToDraft(data.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setContentValue(editorState);

        setValue("description", data.description);
        setState({
          writtenBy: data.created_by,
        });
      }
    }

    setState({ isLoadingCategories: true });
    const returnCategoriesFormatted = true;
    //load categories
    const [categoriesError, categoriesStatus, { categories }] =
      await getCategories(returnCategoriesFormatted);

    setState({ isLoadingCategories: false, categories });

    //load sources
    const returnSourcesFormatted = true;
    const [sourcesError, sourcesStatus, { sources }] = await getSources(
      returnSourcesFormatted
    );

    setState({ isLoadingSources: false, sources });
  },[register, setValue]);

  useEffect(() => {
    findNewsDetails(id);
  }, [findNewsDetails, id]);

  const hasError = (field) => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onEditorStateChange = (data) => {
    setContentValue(data);
    setValue(
      "description",
      draftToHtml(convertToRaw(data.getCurrentContent()))
    );
  };

  const handleSelectedFile = (file) => {
    setState({ featuredImage: file.url });
    hideModal(modalDispatch);
    setTimeout(() => {
      setValue("featured_image_id", file.id);
    }, 250);
  };

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: "Administrador de archivos",
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  const handleAudioChange = (audios) => setValue("audios", audios);

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"} row>
          <Container>
            <PageHeader>Detalles de la noticia</PageHeader>
            {writtenBy && writtenBy != "NOT_DEFINED" && (
              <Text size={14} color={"#000"}>
                Escrita por : {writtenBy}
              </Text>
            )}
          </Container>
          <ImageDownloader
            newsContent={getValues("description")}
            featuredImageUrl={featuredImage}
          />
        </Container>
        <Column padding={"15px"}>
          <Container width={"40%"}>
            {featuredImage && <ImageBackground src={featuredImage} large />}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>Imagen de la nota</Button>
            <Divider size={10} />
            {errors["featured_image_id"] && (
              <Text size={12} color={theme.colors.primary}>
                {errors["featured_image_id"].message}
              </Text>
            )}
          </Container>
          <Divider size={20} />
          <TextInput
            name={"title"}
            label={"Título"}
            ref={register}
            type={"text"}
            error={hasError("title")}
          />
          <Divider size={20} />
          <Container>
            <Container row>
              <Container width={"50%"}>
                <TextInput
                  name={"short_title"}
                  label={"Título Corto"}
                  ref={register}
                  type={"text"}
                  error={hasError("short_title")}
                  maxLength={50}
                />
              </Container>
              <Divider />
              {canPublishNote && (
                <Dropdown
                  title={"Disponible en Ultimas Noticias"}
                  options={settings.publishedValues}
                  defaultValue={settings.publishedValues.find(
                    (item) => item.value == 0
                  )}
                  value={settings.publishedValues.find(
                    (item) => item.value == getValues("in_last_news")
                  )}
                  name={"in_last_news"}
                  onChange={(e) => setValue("in_last_news", e.value)}
                />
              )}
            </Container>
          </Container>
          <Text size={12} color={theme.colors.primary}>
            Max. 50 caracteres.
          </Text>
          <Divider size={20} />
          <Container>
            <Container row>
              <Dropdown
                title={"Sección"}
                options={categories}
                isLoading={isLoadingCategories}
                name={"categoryId"}
                value={
                  !isLoadingCategories &&
                  categories.find(
                    (item) => item.value == getValues("categoryId")
                  )
                }
                onChange={(e) => {
                  setValue("categoryId", e.value);
                }}
              />
              <Divider />
              <Container>
                <TextInput
                  name={"noteId"}
                  label={"ID Nota"}
                  ref={register}
                  type={"text"}
                  error={hasError("noteId")}
                />
              </Container>
            </Container>
            <Divider size={20} />
            <Container>
              <Divider size={20} />
              <Column>
                <Text size={18} color={theme.colors.black}>
                  Detalles Importantes
                </Text>
                <Divider size={20} />
                <Container row>
                  <Container width="50%">
                    <Dropdown
                      title={"Marcar como super destacada"}
                      options={settings.publishedValues}
                      defaultValue={settings.publishedValues.find(
                        (item) => item.value == 0
                      )}
                      value={settings.publishedValues.find(
                        (item) => item.value === getValues("isSuperFeatured")
                      )}
                      name={"isPublished"}
                      onChange={(e) => setValue("isSuperFeatured", e.value)}
                    />
                  </Container>
                  <Divider />
                  <Container width="50%">
                    <TextInput
                      type="datetime-local"
                      label="Cancelar publicación hasta el día:"
                      name="superFeaturedEnds"
                      ref={register}
                    />
                  </Container>
                </Container>
              </Column>
              <Divider size={40} />
            </Container>
            <Container>
              <TagsInput
                label={"Tags"}
                labelStyle={{
                  color: "#000",
                  fontSize: 16,
                  display: "block",
                  marginBottom: 8,
                }}
                shouldSanitize={true}
                tags={getValues("tags") || []}
                onChange={(tags) => setValue("tags", tags)}
              />
            </Container>
            <Container row>
              <Text size={12} color={theme.colors.primary}>
                Escriba y presione "Enter" para agregar otra tag.
              </Text>
              <Divider />
              <Text></Text>
            </Container>
            <Divider size={20} />
          </Container>
          <Divider size={20} />
          <Editor
            defaultEditorState={contentValue}
            editorState={contentValue}
            onEditorStateChange={onEditorStateChange}
            toolbarCustomButtons={[<FileSelector />]}
            editorClassName={"ranch-editor"}
          />
          <Divider size={20} />
          <Container>
            <AudioSelector
              handleChange={handleAudioChange}
              items={getValues("audios") || []}
            />
            <Divider size={20} />
            {canPublishNote && (
              <Dropdown
                title={"Publicada"}
                options={settings.publishedValues}
                defaultValue={settings.publishedValues.find(
                  (item) => item.value === 0
                )}
                value={settings.publishedValues.find(
                  (item) => item.value === getValues("isPublished")
                )}
                name={"isPublished"}
                onChange={(e) => setValue("isPublished", e.value)}
              />
            )}
            <Divider size={20} />
            <Dropdown
              title={"Fuente de información"}
              options={sources}
              isLoading={isLoadingSources}
              name={"source"}
              isSearchable={true}
              value={
                !isLoadingSources &&
                sources.find((item) => item.value === getValues("source"))
              }
              onChange={(e) => {
                setValue("source", e.value);
              }}
            />
          </Container>
          <Divider size={20} />
          <Container row justify={"space-between"}>
            <Container width={"20%"}>
              <Button onClick={handleSubmit(onSubmit)}>Guardar Nota</Button>
            </Container>
            <Container width={"20%"}>
              <Button
                background={"transparent"}
                color={theme.colors.primary}
                onClick={_handleBack}
              >
                Salir
              </Button>
            </Container>
          </Container>
        </Column>
      </Container>
    </Container>
  );
};

export default NewsDetails;
