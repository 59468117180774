import MontserratBold from "./fonts/Montserrat-Bold.ttf";
import MontserratSemiBold from "./fonts/Montserrat-SemiBold.ttf";
import MontserratMedium from "./fonts/Montserrat-Medium.ttf";
import MontserratRegular from "./fonts/Montserrat-Regular.ttf";
import PopppinsMedium from "./fonts/Poppins-Medium.ttf";
import PopppinsRegular from "./fonts/Poppins-Regular.ttf";
import AreaIcons from "./fonts/Area-Icons.ttf";

const fonts = [
  {
    familyName: "icons",
    type: "ttf",
    path: AreaIcons,
  },
  {
    familyName: "montserrat-semi-bold",
    type: "ttf",
    path: MontserratSemiBold,
  },
  {
    familyName: "montserrat-bold",
    type: "ttf",
    path: MontserratBold,
  },
  {
    familyName: "montserrat-medium",
    type: "ttf",
    path: MontserratMedium,
  },
  {
    familyName: "montserrat-regular",
    type: "ttf",
    path: MontserratRegular,
  },
  {
    familyName: "poppins-medium",
    type: "ttf",
    path: PopppinsMedium,
  },
  {
    familyName: "poppins-regular",
    type: "ttf",
    path: PopppinsRegular,
  },
];

export const outputFonts = () => {
  return fonts.map(
    (font) => `
            @font-face {
                font-family: '${font.familyName}';
                src: url('${font.path}'); format('${font.type}');
                font-weight: normal;
                font-style: normal;
            }
        `
  );
};
