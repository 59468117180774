import React from 'react';
import DatePicker from 'react-date-picker';

const RanchDatePicker = React.forwardRef((props, ref) => {
  return (
    <div>
      <span style={{ display: 'block', marginBottom: 5 }}>{props.title}</span>
      <DatePicker {...props} ref={ref} />
    </div>
  );
});

export default RanchDatePicker;
