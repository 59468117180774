import { api } from '@ranch/api';
import * as yup from 'yup';

const profileSchema = yup.object().shape({
  title: yup.string().required('Escriba el nombre del perfil.'),
  modules: yup.array().min(1, 'Seleccione al menos un permiso.'),
});

const formatProfiles = items =>
  items.map(item => {
    return {
      id: item.id,
      title: item.title,
    };
  });

const getProfiles = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/profiles', { params: endpointProps })
    .then(({ status, data }) => {
      const profiles = formatProfiles(data.profiles);
      const totalPages = data.totalItems || 0;
      return [null, status, { profiles }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const createUpdateProfile = (profile, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${profile.id}`;
  const url = `/profiles${params}`;

  return api[action](url, profile)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const getProfile = profileId => {
  const url = `/profiles/${profileId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

export { getProfiles, profileSchema, createUpdateProfile, getProfile };
