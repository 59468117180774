import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const Header = styled.h1`
  font-size: 25px;
  text-align: left;
  color: #000;
`;

export { Header, Container };
