// @flow
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0;
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  ul.pagination {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-top-color: rgb(222, 226, 230);
    border-right-color: rgb(222, 226, 230);
    border-bottom-color: rgb(222, 226, 230);
    border-left-color: rgb(222, 226, 230);
  }
  a {
    text-decoration: none;
  }
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  ul.pagination li.page-item.active a.page-link {
    color: #445565 !important;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
  }
  ul.pagination a.page-link {
    padding: 0.75rem 1rem;
    min-width: 1rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: #6b88a4;
    font-weight: 900;
    font-size: 1rem;
  }
  ul.pagination a.page-link:hover {
    background-color: #f4f4f4;
  }
`;

export { Container };
