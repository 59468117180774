import * as yup from "yup";
import { api } from "@ranch/api";
import { settings } from "@ranch/config";

const weatherSchema = yup.object().shape({
  title: yup.string().required("Escriba el título."),
  description: yup.string().required("Escriba la descripción."),
  maxTempF: yup.number().required("Escriba la temp. Max. en F"),
  minTempF: yup.number().required("Escriba la temp. Min. en F"),
  maxTempC: yup.number().required("Escriba la temp. Max. en C"),
  minTempC: yup.number().required("Escriba la temp. Min. en C"),
  file_id: yup.number().required("Seleccione un archivo."),
  starts_at: yup.string().required("Seleccione la fecha del pronóstico."),
});

const imagesPath = settings.isLocal()
  ? "http://localhost/uploads/images/"
  : settings.imagesUrl;

const formatForecast = (items) =>
  items.map((item) => {
    return {
      ...item,
      image: imagesPath + item?.image?.small_url,
    };
  });

const getForecast = (forecastId) => {
  const url = `/forecast/${forecastId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const getForecasts = (page) => {
  const endpointProps = {
    page,
  };

  return api
    .get("/forecast", { params: endpointProps })
    .then(({ status, data }) => {
      const { forecast } = data;
      const totalPages = forecast?.total || 0;
      return [
        null,
        status,
        { forecast: formatForecast(forecast?.data || []) },
        totalPages,
      ];
    })
    .catch((error) => {
      return [error, null, null, 0];
    });
};

const createUpdateForecast = (forecast, actionType = "create") => {
  const action = actionType === "create" ? "post" : "put";
  const params = actionType === "create" ? "" : `/${forecast.id}`;
  const url = `/forecast${params}`;

  return api[action](url, forecast)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

//handle Clima logic

const updateWeather = (data) => {
  return api
    .post("/weather", data)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const getWeather = (data) => {
  return api
    .get("/weather", data)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

export {
  weatherSchema,
  getForecasts,
  getForecast,
  createUpdateForecast,
  updateWeather,
  getWeather,
};
