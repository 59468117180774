import { api } from "@ranch/api";
import { settings } from "@ranch/config";
import "moment/locale/es";
import moment from "moment";
import * as yup from "yup";

moment().locale("es");

const newsFiltersSchema = yup.object().shape({
  createdAt: yup.string(),
  title: yup.string(),
  published: yup.number(),
  categoryId: yup.number(),
});

const newsDetailsSchema = yup.object().shape({
  createdAt: yup.string(),
  title: yup.string().required("Escriba el título de la nota."),
  published: yup.number(),
  categoryId: yup.number().required("Seleccione una categoría."),
  noteId: yup.string().required("Este campo es requerido."),
  description: yup.string().required("Este campo es requerido."),
  isFeatured: yup.number().required("Este campo es requerido."),
  isPublished: yup.number().required("Este campo es requerido."),
  featured_image_id: yup
    .number()
    .moreThan(0, "Selecciona una imagen")
    .required("Seleccione una imagen."),
  short_title: yup.string().when("in_last_news", {
    is: 1,
    then: yup.string().required("Escriba el título corto."),
    otherwise: yup.string(),
  }),
  in_last_news: yup.number(),
  source: yup.number().required("Seleccione la fuente de información"),
});

const imagesPath = settings.isLocal()
  ? "http://localhost/uploads/images/"
  : settings.imagesUrl;

const formatNews = (items) =>
  items.map((item) => {
    return {
      id: item.id,
      title: item.title,
      createdAt: moment(item.created_at).format("LLL"),
      image: item.image ? imagesPath + item.image.small_url : null,
      category: item.category.title,
      isPublished: item.is_published == 1 ? "Si" : "No",
      isSuperFeatured: item.is_super_featured,
      super_featured_position: item.super_featured_position || 0,
    };
  });

const getSuperFeaturedNews = (limit = 10) => {
  const endpointProps = {
    page: 1,
    limit,
    categoryId: 11,
  };

  return api
    .get("/news", { params: endpointProps })
    .then(({ status, data }) => {
      const news = formatNews(data.news);
      const totalPages = data.totalItems || 0;
      return [null, status, { news }, totalPages];
    })
    .catch((error) => {
      return [error, null, null, 0];
    });
};

const getNews = (
  page = 0,
  keyword = "",
  categoryId = 0,
  orderBy = "",
  published = -1,
  createdAt = null,
  inLastNews = 0,
  superFeatured = 0,
  limit = 10
) => {
  const endpointProps = {
    page,
  };

  if (inLastNews > -1) {
    endpointProps.inLastNews = inLastNews;
  }

  if (categoryId > 0) {
    endpointProps.categoryId = categoryId;
  }

  if (keyword && keyword.length > 0) {
    endpointProps.keyword = keyword;
  }

  if (orderBy && orderBy.length > 0) {
    endpointProps.orderBy = orderBy;
  }

  if (published > -1) {
    endpointProps.published = published;
  }

  if (createdAt) {
    endpointProps.createdAt = createdAt;
  }

  if (limit > 0) {
    endpointProps.limit = limit;
  }

  if (superFeatured > 0) {
    endpointProps.superFeatured = superFeatured;
    endpointProps.limit = 9999;
  }

  return api
    .get("/news", { params: endpointProps })
    .then(({ status, data }) => {
      const news = formatNews(data.news);
      const totalPages = data.totalItems || 0;
      return [null, status, { news }, totalPages];
    })
    .catch((error) => {
      return [error, null, null, 0];
    });
};

const getNewsDetails = (id) => {
  return api
    .get(`/news/${id}`)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => {
      return [error, null, null];
    });
};

const updateSuperFeaturedPositions = (newsList) => {
  return api
    .post(`/news/update-super-featured-position`, newsList)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => {
      return [error, null, null];
    });
};

const createUpdateNote = (news, actionType = "create") => {
  const action = actionType === "create" ? "post" : "put";
  const params = actionType === "create" ? "" : `/${news.id}`;

  const url = `/news${params}`;

  return api[action](url, news)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch((error) => [error, null, null]);
};

const findTags = (keyword = "") => {
  const endpointProps = {
    keyword,
  };
  return api
    .get("/tags", { params: endpointProps })
    .then(({ status, data }) => {
      const tags = data.tags.map((item) => {
        return { label: item.title, value: item.id };
      });
      return [null, status, { tags }];
    })
    .catch((error) => {
      return [error, null, null];
    });
};

const deleteNote = (id) =>
  api
    .delete("/news/" + id)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

export {
  getNews,
  getNewsDetails,
  newsFiltersSchema,
  newsDetailsSchema,
  createUpdateNote,
  findTags,
  deleteNote,
  getSuperFeaturedNews,
  updateSuperFeaturedPositions,
};
