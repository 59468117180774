import * as yup from 'yup';
import { api } from '@ranch/api';

const liveLinkSchema = () =>
  yup.object().shape({
    name: yup.string().required('Escriba el nombre'),
    url: yup.string().required('Escriba el enlace.'),
    notification_title : yup.string().required('Escriba el título de la notificatión.'),
  });


const getLiveLinks = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/live-links', { params: endpointProps })
    .then(({ status, data }) => {
      const _data = data.liveLinks
      const liveLinks = _data.data
      const totalPages = data.total || 0;
      return [null, status, { liveLinks }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const getLiveLink = liveLinkId => {
  const url = `/live-links/${liveLinkId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const createUpdateLiveLink = (liveLink, actionType = 'put') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${liveLink.id}`;
  const url = `/live-links${params}`;

  return api[action](url, liveLink)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const deleteLiveLink = (id) =>
  api
    .delete("/live-links/" + id)
    .then(({ status, data }) => [null, status, data])
    .catch((error) => [error, null, null]);

export {
  liveLinkSchema,
  getLiveLinks,
  getLiveLink,
  createUpdateLiveLink,
  deleteLiveLink
};
