import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.white};
  ${({ padding }) => padding && `padding:${padding}`};

  ${({ flex }) => flex > -1 && `flex:${flex} !important;`};

  ${({ row }) =>
    row &&
    `
        flex-direction:row;
        align-items:center;
    `};

  ${({ justify }) =>
    justify &&
    `
    justify-content:${justify};
    `};

  ${({ width }) =>
    width &&
    `
     max-width:${width};
   `};

  ${({ wrap }) =>
    wrap &&
    `
     flex-wrap:wrap;
   `};
`;

export default Container;
