export default {
  settings: '',
  user: '',
  plus: '',
  news: '',
  edit: '',
  image: '',
  video: '',
  audio: '',
  tags: '',
  location: '',
  bell: '',
  calendar: '',
  search: '',
  dashboard: '',
  trash: '',
  eye: '',
  eyeCover: '',
  warning: '',
  error: '',
  logout: '',
  arrowRight: '',
  arrowLeft: '',
  warning: '',
  equalizer: '',
  forward: '',
  rewind: '',
  pause: '',
  play: '',
  videocam: '',
  callMade: '',
  callReceived: '',
  email: '',
  locationTwo: '',
  add: '',
  clear: '',
  barChart: '',
  attachment: '',
  upload: '',
  download: '',
  arrowBack: '',
  arrowForward: '',
  check: '',
  expandLess: '',
  expandMore: '',
  dotsHor: '',
  dotsVert: '',
  calendarTwo: '',
  downloadTwo: '',
  pdf: '',
  notifications: '',
  person: '',
  share: '',
  salutations: '',
  notifications2: '',
  weather: '',
  userGroup: '',
  profile: '',
  poll: '',
  person: '',
  micro: '',
};
