import React, { useContext, useState, useEffect } from 'react'
import {
    Container,
    Column,
    Divider,
    FileManager,
    Button,
    Icon,
    Text,
  } from '@ranch/components';
import ModalContext from '@ranch/contexts/ModalContext';
import { setModal, hideModal } from '@ranch/contexts/ModalContext/actions';

const AudioSelector = ({ items = [], handleChange }) => {

    const [audios, setAudios] = useState([])

    const { dispatch: modalDispatch } = useContext(ModalContext);

    useEffect(() => {
        if(items && items.length > 0){
            setAudios(items)
        }
    },[items])

    const handleSelectedFile = file => {
        //api call
        if(file){
            const newAudios = [
                ...audios,
                { fileId : file.id, url : file.url }
            ]
            setAudios(newAudios)
            hideModal(modalDispatch);
            setTimeout(() => handleChange(newAudios), 0);
        }
    }

    const selectFiles = () =>
        setModal(modalDispatch, {
        title: 'Administrador de archivos',
        children: <FileManager onFileSelected={handleSelectedFile} />,
        useButton: false,
        extraProps: {},
    });

    const handleDelete = (itemIdx) => {
        const confirm = window.confirm('¿Seguro que deseas eliminar el audio?')
        if(confirm){
            const newItems = audios.filter((item,idx) => idx !== itemIdx)
            setAudios(newItems)
            handleChange(newItems)
        }
    }

    const renderAudios = () => {
        if(audios.length > 0){
            return audios.map((item,idx) => (
                <div key={`audioFile-${idx}`} >
                    <Divider size={10} />
                    <Container row color={'transparent'}>
                        <audio controls>
                            <source src={item.url} type="audio/ogg" />
                            <source src={item.url} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                        <Divider />
                        <div onClick={() => handleDelete(idx)} style={{cursor:'pointer'}}>
                            <Icon name="trash" size={19} style={{
                                color : 'red'
                            }} />
                        </div>
                    </Container>
                </div>
            ))
        }

        return <div><Divider size={10} /><Text size={16} color={'red'}>Sin audios adjuntos</Text></div>
    }

    return (
        <Column>
            <Button onClick={selectFiles}>Añadir Audios</Button>
            {renderAudios()}
        </Column>
    )
}

export default AudioSelector