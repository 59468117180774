import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Column,
  TextInput,
  Divider,
  Button,
} from '@ranch/components';
import logo from '../assets/logo.png';
import { notify } from 'react-notify-toast';
import ReCAPTCHA from "react-google-recaptcha";


import { yupResolver } from '@hookform/resolvers';
import { loginSchema, authenticateUser } from '@ranch/models/users';
import { store, settings } from '@ranch/config';

const LoginScreen = () => {
  const [reToken, setReToken] = useState(undefined);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const theme = useTheme();
  const history = useHistory();

  const onSubmit = async data => {
    if(!reToken) {
      notify.show("El captcha no es correcto", 'error');
      return
    }

    const [error, status, response] = await authenticateUser({
      email: data.email,
      password: data.password,
      token: reToken,
    });

    if (error || status !== 200) {
      //show error message
      notify.show(error, 'error');
    }

    if (response) {
      const { message } = response;

      if (message && message.length > 0) {
        //show errors message
        notify.show(message, 'error');
      } else {
        const { token, data } = response;
        const { modules } = data;

        if (token && modules) {
          store.setItem('modules', JSON.stringify(modules));
          store.setItem(settings.AUTHTOKEN, token);

          //verificar que los perfiles que no tengan acceso a las notas no se redirija, solo a la sección principal [0] a la que tienen acceso
          const firstPage = modules[0].slug;
          history.replace(`/dashboard/${firstPage}`);
        }
      }
    }
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const onCaptchaChange = (e) => setReToken(e);

  return (
    <Container>
      <Row>
        <Column width={'33%'} justify={'center'} padding={'0 40px 0 40px'}>
          <TextInput
            name={'email'}
            label={'Correo electrónico'}
            ref={register}
            type={'email'}
            error={hasError('email')}
          />
          <Divider />
          <TextInput
            name={'password'}
            label={'Contraseña'}
            ref={register}
            type={'password'}
            error={hasError('password')}
          />
          <Divider />
          <ReCAPTCHA
            sitekey={'6LfBPmwpAAAAAKlxSnIsw6tY3nk53zinljx3wIGa'}
            onChange={onCaptchaChange}
          />
          <Divider />
          <Button onClick={handleSubmit(onSubmit)}>Iniciar sesión</Button>
        </Column>
        <Column
          width={'67%'}
          color={theme.colors.primary}
          justify={'center'}
          align={'center'}
        >
          <img src={logo} style={{ width: '30%' }} />
        </Column>
      </Row>
    </Container>
  );
};

export default LoginScreen;
