import React, { useReducer, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import {
  Container,
  Table,
  PageHeader,
  Pagination,
  Button,
  Icon,
} from '@ranch/components';
import { routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import { getPrograms, deleteProgram } from '@ranch/models/programs';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const ProgramsScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    programs: [],
    error: null,
    totalPages: 0,
  });

  const { loading, programs, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchPrograms = async () => {
    setState({ loading: true, error: null, weather: [] });
    const [error, status, { programs }, totalPages] = await getPrograms(page);

    if (status !== 200 || error) {
      notify.show('Ha ocurrido un error.', 'error');
    }

    if (status === 200 && programs) {
      setState({ programs, error: null, loading: false, totalPages });
    }
  };

  const handleDeleteProgram = async (programId) => {
    if(programId){
      const confirm = window.confirm('¿Deseas eliminar permanentemente esta programación?')

      if(confirm){
        const [error, status, data] = await deleteProgram(programId);
        if(error || status !== 200){
          notify.show('Error al borrar la programación','error')
        }else{
          notify.show('Programación borrada correctamente','success')
          setTimeout(() => {
            window.location.reload();
          },2000)
        }
      }
    }
  } 
  
  useEffect(() => {
    fetchPrograms();
  }, []);

  const renderActions = cellData => {
    const { id } = cellData.row.original;
    const iconStyle = { color: theme.colors.black, fontSize: 18 };
    return (
      <Container
        color={'transparent'}
        row
        padding={'0px 15px 0 0'}
      >
        <div onClick={() => history.push(routes.PROGRAM_ITEM(id))}>
          <Icon name="eye" style={iconStyle} />
        </div>
        <div style={{width:40}}></div>
        <div onClick={() => handleDeleteProgram(id)}>
          <Icon name="trash" style={iconStyle} />
        </div>
      </Container>
    );
  };

  const programsColumns = [
    {
      Header: 'Título',
      accessor: 'title',
      width: 400,
    },
    {
      Header: 'Horarios',
      accessor: 'schedule',
      width: 400,
    },
    {
      Header: 'Orden',
      width: 400,
      accessor: 'order_shown',
    },
    {
      Header: 'Acciones',
      accessor: '',
      width: 150,
      Cell: cell => renderActions(cell),
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Programación</PageHeader>
          <Button
            width={'160px'}
            onClick={() => history.push(routes.PROGRAM_ITEM('new'))}
          >
            Crear Programación
          </Button>
        </Container>
        <Container padding={'15px'}>
          <Table columns={programsColumns} data={programs} loading={loading} />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default ProgramsScreen;
