const storage = window.localStorage;
const storageKey = 'ranch.';

const setItem = (key, value) => {
  const keyName = storageKey + '' + key;
  storage.setItem(keyName, value);
};

const getItem = key => {
  const keyName = storageKey + '' + key;
  return storage.getItem(keyName);
};

const deleteItem = key => {
  storage.removeItem(key);
};

export { setItem, getItem, deleteItem };
