import * as yup from 'yup';
import { api } from '@ranch/api';
import { settings } from '@ranch/config';

const announcerSchema = yup.object().shape({
  firstName: yup.string().required('Escriba el nombre del locutor.'),
  lastName: yup.string().required('Escriba el apellido del locutor.'),
  email: yup
    .string()
    .email('Email inválido.')
    .required('Escriba el email del locutor.'),
  phrases: yup.string(),
  comments: yup.string(),
  schedules: yup.string().required('Escriba los horarios.'),
  programs: yup.string().required('Escriba los programas del locutor.'),
  published: yup.number().required(),
  file_id: yup.number(),
});

const imagesPath = settings.isLocal()
  ? 'http://localhost/uploads/images/'
  : settings.imagesUrl;

const formatAnnouncers = items =>
  items.map(item => {
    return {
      id: item.id,
      name: `${item.firstName} ${item.lastName}`,
      programs: item.programs,
      published: item.published == 1 ? 'Activo' : 'Inactivo',
      email: item.email,
      image: imagesPath + item?.image?.small_url,
    };
  });

const getAnnouncers = page => {
  const endpointProps = {
    page,
  };

  return api
    .get('/announcer', { params: endpointProps })
    .then(({ status, data }) => {
      const { announcers } = data;
      const totalPages = announcers?.total || 0;
      return [
        null,
        status,
        { announcers: formatAnnouncers(announcers?.data || []) },
        totalPages,
      ];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const getAnnouncer = announcerId => {
  const url = `/announcer/${announcerId}`;

  return api
    .get(url)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const createUpdateAnnouncer = (user, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${user.id}`;
  const url = `/announcer${params}`;

  return api[action](url, user)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};

const deleteAnnouncer = id => api
.delete('/announcer/'+id)
.then(({ status, data }) => [null, status, data])
.catch(error => [error, null, null]);

export { getAnnouncers, getAnnouncer, createUpdateAnnouncer, announcerSchema, deleteAnnouncer };
