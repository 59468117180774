import React, { useReducer, useEffect } from "react";

import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import {
  Container,
  PageHeader,
  TextInput,
  Button,
  Row,
} from "@ranch/components";
import { Column } from "../components";
import { notify } from "react-notify-toast";
import { capitalize } from "../utils/utils";
import { updateWeather, getWeather } from "@ranch/models/weather";

const Header = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const WeatherWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 16px 8px;
  margin-bottom: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const dayName = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

const Clima = () => {
  const history = useHistory();
  const theme = useTheme();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    monday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    tuesday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    wednesday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    thursday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    friday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    saturday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
    sunday: {
      minC: "",
      maxC: "",
      minF: "",
      maxF: "",
    },
  });

  const init = async () => {
    const [error, status, data] = await getWeather();

    if (error || status > 300) {
      notify.show(
        "Ocurrió un error al obtener la información del clima.",
        "error"
      );
      return;
    }

    const weather = data.weather;

    if (weather.length) {
      days.forEach((dayName) => {
        const dayData = weather.find((item) => item.day == dayName);

        setState({
          [dayName]: {
            minC: dayData.minC,
            maxC: dayData.maxC,
            minF: dayData.minF,
            maxF: dayData.maxF,
          },
        });
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const _handleSave = async () => {
    const [error, status, data] = await updateWeather(state);

    if (error || status >= 300) {
      notify.show(
        "Ocurrió un error al actualizar el widget del clima",
        "error"
      );
      return;
    }

    notify.show("Widget del clima actualizado correctamente", "success");
  };

  const _handleInputChange = (dayName, fieldName, value) => {
    setState({
      [dayName]: {
        ...state[dayName],
        [fieldName]: value,
      },
    });
  };

  const _renderDayInfo = () => {
    return days.map((item, index) => {
      return (
        <Column key={`weather-day-${index}`}>
          <Header>{dayName[item]}</Header>
          <WeatherWrapper>
            <Row justify={"space-between"}>
              <Column>
                <TextInput
                  label={"Min C."}
                  onChange={(txt) =>
                    _handleInputChange(item, "minC", txt.target.value)
                  }
                  value={state[item].minC}
                />
              </Column>
              <Column>
                <TextInput
                  label={"Max C."}
                  onChange={(txt) =>
                    _handleInputChange(item, "maxC", txt.target.value)
                  }
                  value={state[item].maxC}
                />
              </Column>
              <Column>
                <TextInput
                  label={"Min F."}
                  onChange={(txt) =>
                    _handleInputChange(item, "minF", txt.target.value)
                  }
                  value={state[item].minF}
                />
              </Column>
              <Column>
                <TextInput
                  label={"Max F."}
                  onChange={(txt) =>
                    _handleInputChange(item, "maxF", txt.target.value)
                  }
                  value={state[item].maxF}
                />
              </Column>
            </Row>
          </WeatherWrapper>
        </Column>
      );
    });
  };

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container row flex={0} padding={"15px"} justify={"space-between"}>
          <Column>
            <PageHeader>Widget del Clima</PageHeader>
            <p>
              Llene los datos siguientes, el sitio web se encarga de mostrar la
              información día con día.
            </p>
          </Column>
        </Container>
        <Container padding={"15px"}>
          {_renderDayInfo()}
          <Container width={"20%"}>
            <Button color={theme.colors.white} onClick={_handleSave}>
              Guardar
            </Button>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default Clima;
