import React, { useReducer, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import {
  Container,
  Table,
  PageHeader,
  Pagination,
  RadioButton,
  Icon,
} from '@ranch/components';
import { routes } from '@ranch/config';
import useQueryParams from '@ranch/hooks/useQueryParams';
import {
  getSalutations,
  createOrUpdateSalutation,
} from '@ranch/models/salutations';
import moment from 'moment';

const DEFAULT_QUERY_VALUES = {
  page: 0,
};

const SalutationsScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    loading: false,
    salutations: [],
    error: null,
    totalPages: 0,
  });

  const { loading, salutations, error, totalPages } = state;

  const [queryString, setQueryParam] = useQueryParams(DEFAULT_QUERY_VALUES);
  const { page } = {
    ...DEFAULT_QUERY_VALUES,
    ...queryString,
  };

  const fetchSalutations = async () => {
    setState({ loading: true, error: null, weather: [] });
    const [error, status, { salutations }, totalPages] = await getSalutations(
      page
    );

    if (status !== 200 || error) {
      notify.show('Ha ocurrido un error.', 'error');
    }

    if (status === 200 && salutations) {
      setState({ salutations, error: null, loading: false, totalPages });
    }
  };

  useEffect(() => {
    fetchSalutations();
  }, []);

  const handlePublishSalutation = async salutation => {
    //update salutation publishment
    salutation.is_published = !salutation.is_published;
    const [error, status, { message }] = await createOrUpdateSalutation(
      salutation,
      'update'
    );

    if (status === 200) {
      if (message === 'UPDATED_SALUTATION') {
        notify.show('Saludo actualizado correctamente.', 'success');
      }
    }

    if (status === 202) {
      if (message === 'ERROR_UPDATING_SALUTATION') {
        notify.show('Error al actualizar saludo.', 'error');
      }
    }
  };

  const salutationColumns = [
    {
      Header: 'Título',
      accessor: 'title',
      width: 400,
    },
    {
      Header: 'Saludo',
      accessor: 'content',
      width: 400,
    },
    {
      Header: 'Email',
      width: 400,
      accessor: 'email',
    },
    {
      Header: 'Locutor',
      width: 400,
      Cell: ({ row }) => {
        const { announcer } = row.original;
        return <span>{`${announcer.firstName} ${announcer.lastName}`}</span>;
      },
    },
    {
      Header: 'Fecha de creación',
      width: 400,
      Cell: ({ row }) => {
        const { created_at } = row.original;
        return <span>{moment(created_at).format('LLL')}</span>;
      },
    },
    {
      Header: 'Publicada',
      width: 400,
      Cell: ({ row }) => {
        const { is_published } = row.original;

        return (
          <RadioButton
            label={'Hello'}
            onClick={() => handlePublishSalutation(row.original)}
            isActive={is_published}
          />
        );
      },
    },
  ];

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={'50px'}>
        <Container row padding={'15px'} justify={'space-between'} flex={0}>
          <PageHeader>Saludos</PageHeader>
        </Container>
        <Container padding={'15px'}>
          <Table
            columns={salutationColumns}
            data={salutations}
            loading={loading}
          />
        </Container>
        {totalPages > 0 && (
          <Pagination
            totalRecords={totalPages}
            pageLimit={10}
            pageNeighbours={10}
            onPageChanged={page => setQueryParam({ page: page.currentPage })}
          />
        )}
      </Container>
    </Container>
  );
};

export default SalutationsScreen;
