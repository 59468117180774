import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ align }) => align && `align-items:${align}`};
  ${({ justify }) => justify && `justify-content:${justify}`};
  ${({ width }) => width && `width:${width}`};
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.white};
  height: 100%;
  ${({ padding }) => padding && `padding:${padding}`};
`;

export default Column;
