import styled from 'styled-components';

const Text = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};

  ${({ color }) => color && `color:${color}`};
  ${({ size }) => size && `font-size:${size}px`};

  ${({ regular }) =>
    regular &&
    `
        font-family:'montserrat-regular';
    `};

  ${({ medium }) =>
    medium &&
    `
        font-family:'montserrat-medium';
    `};

  ${({ bold }) =>
    bold &&
    `
        font-family:'montserrat-bold';
    `};

  ${({ semiBold }) => semiBold && `font-family:'montserrat-semi-bold'`};

  ${({ sub }) =>
    sub &&
    `
        text-decoration:underline;
    `};

  ${({ clickable }) =>
    clickable &&
    `
        cursor:pointer;
        text-decoration:underline;
    `};

  ${({ align }) => align && `text-align:center`};
`;

export default Text;
