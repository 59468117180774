import React, { useState, useRef, useEffect } from 'react';
import { Container, Text, Divider, Icon, Checkbox, ActivityIndicator, TextInput, Row, Column, Button } from '@ranch/components';
import { colors } from '@ranch/config';
import { Wrapper, ImageWrap, ImagePreview } from './styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { fileUploadSchema } from '@ranch/models/files';
/*class FileDrop extends Component {
  state = {
    drag: false,
    imageWithWatermark : false,
    isPreview: false,
    file: null,
    previewFile: null,
  };

  dropRef = React.createRef();

  fileRef = React.createRef();

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = e => {
    const { allowedFiles } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      const filteredFiles = [];

      Object.keys(files).forEach(file => {
        if (allowedFiles.includes(files[file].type)) {
          filteredFiles.push(files[file]);
        }
      });

      // e.dataTransfer.clearData();
      this.dragCounter = 0;

      this.setState({ isPreview: true })

      // format file into base64
      const reader = new FileReader()
      reader.onloadend = () => {
        // preview the file
        this.setState({ previewFile : reader.result, file: filteredFiles[0] });
      }
      reader.readAsDataURL(filteredFiles[0])
      //this.props.handleDrop(filteredFiles, this.state.imageWithWatermark);
    }
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleChange = event => {
    const { handleDrop, allowedFiles } = this.props;
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;
    const filteredFiles = [];
    Object.keys(files).forEach(file => {
      if (allowedFiles.includes(files[file].type)) {
        filteredFiles.push(files[file]);
      }
    });

    handleDrop(filteredFiles, this.state.imageWithWatermark);
  };

  handleWaterMarkChange = event => {
    this.setState({
      imageWithWatermark : event.target.checked
    })
  }

  conditionalRender(){
    if(this.state.isPreview){

      if(!this.state.file && !this.state.previewFile){
        return <ActivityIndicator />
      }

      console.log(this.state.file,this.state.previewFile,'here')
      return <Wrapper>
        <ImagePreview src={this.state.previewFile} />
        <Divider />
        <TextInput
            name={'email'}
            label={'Correo electrónico'}
            ref={register}
            type={'email'}
            error={hasError('email')}
          />
          <Divider />
          <TextInput
            name={'password'}
            label={'Contraseña'}
            ref={register}
            type={'password'}
            error={hasError('password')}
          />
      </Wrapper>
    }

    const { isUploading } = this.props;

    return <React.Fragment>
      <Wrapper ref={this.dropRef}>
          <Text align="center">
            <Icon
              name="upload"
              style={{
                fontSize: 300,
                color: colors.primary,
              }}
            />
          </Text>
          <Text color={colors.gray4} regular size={16} align="center">
            ARRASTRA Y SUELTA
          </Text>
          <Divider size={5} />
          <Text color={colors.gray4} regular size={14} align="center">
            tu archivo aquí, o
          </Text>
          <Divider size={5} />
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => this.fileRef.current.click()}
            color={colors.primary}
            semiBold
            size={14}
            align="center"
          >
            explora en tu PC
          </Text>
          <br />
          <label style={{textAlign:'center'}}>
            <Checkbox
              checked={this.state.imageWithWatermark}
              onChange={this.handleWaterMarkChange}
            />
            <span style={{ marginLeft: 8 }}>Añadir marca de agua a las imágenes subidas (no funciona en formatos .gif o con movimiento)</span>
          </label>
          <input
            type="file"
            id="file"
            ref={this.fileRef}
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png,.mp4,.mp3,.gif,.mpeg,.ogg"
            onChange={this.handleChange}
          />
          {isUploading && (
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,.8)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 99,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <Text semiBold size={18} color={colors.primary}>
                  SUBIENDO ARCHIVO, ESPERE ...
                </Text>
              </div>
            </div>
          )}
          {this.state.drag && (
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,.8)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 99,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <Text semiBold size={18} color={colors.primary}>
                  SUELTA TU ARCHIVO
                </Text>
              </div>
            </div>
          )}
        </Wrapper>
        {this.props.children}
    </React.Fragment>
  }

  render() {
    return (
      <div>
        {this.conditionalRender()}
      </div>
    );
  }
}*/

const FileDrop = (props) => {
  const [drag, setDrag] = useState(false);
  const [imageWithWatermark, setImageWithWatermark] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);

  const dropRef = useRef();
  const fileRef = useRef();

  const { allowedFiles, isUploading } = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(fileUploadSchema),
  });

  let dragCounter = 0;

  const onSubmit = async (data) => {
    if(!file || !data.title || !data.caption) return

    const { handleDrop } = props
    await handleDrop([file], imageWithWatermark, data.title, data.caption);
    setIsPreview(false);
  }

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleDrop = (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      const filteredFiles = [];

      Object.keys(files).forEach((file) => {
        if (allowedFiles.includes(files[file].type)) {
          filteredFiles.push(files[file]);
        }
      });

      dragCounter = 0;

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewFile(reader.result);        
      };
      reader.readAsDataURL(filteredFiles[0]);
      setFile(filteredFiles[0]);

      setIsPreview(true);
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleChange = (event) => {
    const { handleDrop, allowedFiles } = props;
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;
    const filteredFiles = [];
    Object.keys(files).forEach((file) => {
      if (allowedFiles.includes(files[file].type)) {
        filteredFiles.push(files[file]);
      }
    });

    handleDrop(filteredFiles, imageWithWatermark);
  };

  const handleWaterMarkChange = (event) => {
    setImageWithWatermark(event.target.checked);
  };

  const hasError = field => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };


  const conditionalRender = () => {
    if (isPreview) {
      if (!file && !previewFile) {
        return <ActivityIndicator />;
      }

      const fileType = file.type

      return (
        <Wrapper>
          <Row align="center" justify="center" color="#EDEDED">
            {fileType.includes('video') && <div style={{ width:250, height: 480, position:'relative', backgroundColor:'black' }}>
                <video src={previewFile} controls width='100%' height='100%' />
              </div>}
            
            {fileType.includes('image') && <ImagePreview src={previewFile} />}
            {fileType.includes('audio') && <audio src={previewFile} controls />}
            <Divider />
            <Column color="#EDEDED">
              <TextInput
                name={'title'}
                label={'Título'}
                ref={register}
                type={'text'}
                error={hasError('title')}
              />
              <Divider />
              <TextInput
                name={'caption'}
                label={'Pie de archivo'}
                ref={register}
                type={'text'}
                error={hasError('caption')}
              />
              <Divider />
              {isUploading && <ActivityIndicator />}
              {!isUploading && <Button onClick={handleSubmit(onSubmit)}>Subir ahora</Button>}
            </Column>
          </Row>
        </Wrapper>
      );
    }
    

    return (
      <>
        <Wrapper ref={dropRef}>
          <Text align="center">
            <Icon
              name="upload"
              style={{
                fontSize: 300,
                color: colors.primary,
              }}
            />
          </Text>
          <Text color={colors.gray4} regular size={16} align="center">
            ARRASTRA Y SUELTA
          </Text>
          <Divider size={5} />
          <Text color={colors.gray4} regular size={14} align="center">
            tu archivo aquí
          </Text>
          {/*<Divider size={5} />
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => fileRef.current.click()}
            color={colors.primary}
            semiBold
            size={14}
            align="center"
          >
            explora en tu PC
            </Text>*/}
          <br />
          <label style={{ textAlign: 'center' }}>
            <Checkbox checked={imageWithWatermark} onChange={handleWaterMarkChange} />
            <span style={{ marginLeft: 8 }}>
              Añadir marca de agua a las imágenes subidas (no funciona en formatos .gif, .mp4 y .mp3)
            </span>
          </label>
          <input
            type="file"
            id="file"
            ref={fileRef}
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png,.mp4,.mp3,.gif,.mpeg,.ogg"
            onChange={handleChange}
          />
          {isUploading && (
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,.8)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 99,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <Text semiBold size={18} color={colors.primary}>
                  SUBIENDO ARCHIVO, ESPERE ...
                </Text>
              </div>
            </div>
          )}
          {drag && (
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,.8)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 99,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <Text semiBold size={18} color={colors.primary}>
                  SUELTA TU ARCHIVO
                </Text>
              </div>
            </div>
          )}
        </Wrapper>
        {props.children}
      </>
    );
  };

  return <div>{conditionalRender()}</div>;
};

const FileDropItem = ({ name, type, src, onRemove }) => {
  const render = () => {
    if (type && type.includes('image')) {
      return (
        <Container.UnFlex width="30%" style={{ marginBottom: 10 }}>
          <ImageWrap src={src} />
          <Divider size={8} />
          <Text regular size={10} color={colors.black}>
            <Text
              clickable
              onClick={() => onRemove()}
              regular
              size={15}
              color={colors.black}
              style={{ textDecoration: 'none' }}
            >
              &times;
            </Text>
            {name.substring(0, 15)}...
          </Text>
        </Container.UnFlex>
      );
    }

    return <div />;
  };

  return render();
};

FileDrop.Item = FileDropItem;

export default FileDrop;
