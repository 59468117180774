import { api } from '@ranch/api';
import * as yup from 'yup';
import { settings } from '@ranch/config';

const pollSchema = yup.object().shape({
  title: yup.string().required('Título requerido.'),
  starts_at: yup.string().required('Fecha de inicio requerida.'),
  ends_at: yup.string().required('Fecha de fin requerida.'),
  options: yup
    .array()
    .min(2, 'Escriba al menos 2 opciones.')
    .required('Opciones requeridas.'),
});

const imagesPath = settings.isLocal()
  ? 'http://localhost/uploads/images/'
  : settings.imagesUrl;

const formatPolls = items =>
  items.map(item => {
    return {
      id: item.id,
      title: item.title,
      options: item.options,
      image: item.image ? imagesPath + item.image.small_url : null,
      starts_at : item.starts_at,
      ends_at: item.ends_at,
      results : item.options.map(data => {
        return {
          title : data.title,
          votes : data.total
        }
      })
    };
  });

const getPolls = ({ page = 1 } = {}) => {
  return api
    .get('/polls?page=' + page)
    .then(({ status, data }) => {
      const { polls } = data;
      const totalPages = polls?.total || 0;
      return [null, status, { polls: formatPolls(polls?.data) }, totalPages];
    })
    .catch(error => {
      return [error, null, null, 0];
    });
};

const findPoll = pollId => {
  return api
    .get(`/polls/${pollId}`)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => {
      return [error, null, null];
    });
};

const createUpdatePoll = (poll, actionType = 'create') => {
  const action = actionType === 'create' ? 'post' : 'put';
  const params = actionType === 'create' ? '' : `/${poll.id}`;

  const url = `/polls${params}`;

  return api[action](url, poll)
    .then(({ status, data }) => {
      return [null, status, data];
    })
    .catch(error => [error, null, null]);
};
export { getPolls, pollSchema, findPoll, createUpdatePoll };
