import React from "react";
import { useTheme } from "styled-components";
import {
  Container,
  Header,
  Sidebar,
  Row,
  PrivateRoute,
} from "../components";
import {
  NewsScreen,
  NewsDetails,
  ProfilesScreen,
  ProfileDetails,
  UsersScreen,
  UserDetails,
  AnnouncersScreen,
  AnnouncersDetails,
  NotificationsScreen,
  PollsScreen,
  PollDetailsScreen,
  WeatherDetails,
  WeatherScreen,
  ProgramsScreen,
  ProgramsDetails,
  SalutationsScreen,
  PostsScreen,
  PostsDetails,
  MultimediaScreen,
  MultimediaDetails,
  SourceScreen,
  SourceDetails,
  Clima,
  LiveLinksScreen,
  LiveLinksDetails
} from "../routes";

const Dashboard = () => {
  const theme = useTheme();

  return (
    <Container>
      <Header></Header>
      <Row>
        <Sidebar />
        <Row width={"95%"} color={theme.colors.grayLight}>
          <PrivateRoute exact path={"/dashboard/news"} component={NewsScreen} />
          <PrivateRoute
            exact
            path={"/dashboard/sources"}
            component={SourceScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/sources/:id"}
            component={SourceDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/news/:id"}
            component={NewsDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/createNews"}
            component={NewsDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/cloneNews/:id"}
            component={NewsDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/profiles"}
            component={ProfilesScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/profile/:id"}
            component={ProfileDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/users"}
            component={UsersScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/users/:id"}
            component={UserDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/announcers"}
            component={AnnouncersScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/announcers/:id"}
            component={AnnouncersDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/notifications"}
            component={NotificationsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/polls"}
            component={PollsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/poll/:id"}
            component={PollDetailsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/weather"}
            component={WeatherScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/weather/:id"}
            component={WeatherDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/programs"}
            component={ProgramsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/programs/:id"}
            component={ProgramsDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/salutations"}
            component={SalutationsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/posts"}
            component={PostsScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/posts/:id"}
            component={PostsDetails}
          />
          <PrivateRoute
            exact
            path={"/dashboard/multimedia"}
            component={MultimediaScreen}
          />
          <PrivateRoute
            exact
            path={"/dashboard/multimedia/:id"}
            component={MultimediaDetails}
          />
          <PrivateRoute exact path={"/dashboard/clima"} component={Clima} />
          <PrivateRoute exact path={"/dashboard/live-links"} component={LiveLinksScreen} />
          <PrivateRoute exact path={"/dashboard/live-links/:id"} component={LiveLinksDetails} />
        </Row>
      </Row>
    </Container>
  );
};

export default Dashboard;
