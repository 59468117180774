import React, { useState, useEffect } from 'react';
import { Text, Divider } from '@ranch/components';
import { Row, Radio } from './styles';

const RadioButton = ({ label, onClick, isActive = false }) => {
  const [active, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <Row>
      <Radio
        onClick={() => {
          if (onClick) {
            onClick();
          }
          setActive(!active);
        }}
        active={active}
      />
      <Divider size={10} />
      <Text regular>{label}</Text>
    </Row>
  );
};

export default RadioButton;
