import React from 'react'
import { Text, Divider, Icon } from '@ranch/components';
import { Wrapper, Image, Container, DownloadWrapper } from './styles'

const ImageDownloader = ({ newsContent = '', featuredImageUrl = '' }) => {

    const linkify = (text) => {
        const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const foundLinks = []
        text.replace(urlRegex, (url) => {
            const extension = url.split('.').pop()
            if(['png','jpg','jpeg','gif'].includes(extension)){            
                foundLinks.push(url)
            }
        });
        return foundLinks
    }

    const handleDownloadImage = async (url) => {
        if(url){
            const image = await fetch(url)
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)

            const link = document.createElement('a')
            link.href = imageURL
            link.download = 'Rancherita-image'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    const renderItems = () => {
        const urls = linkify(newsContent)
        return urls.map((item,idx) => <Image key={`image-${idx}`} src={item}>
            <DownloadWrapper onClick={() => handleDownloadImage(item)}>
                <Icon name="download" size={12} color={'#fff'}/>
            </DownloadWrapper>
        </Image>)
    }

    renderItems()

    return (
        <Container>
            <Text size={16} bold color={'#000'}>Imágenes de la nota</Text>
            <Divider size={10} />
            <Wrapper>
                {renderItems()}
            </Wrapper>
        </Container>
    )
}

export default ImageDownloader