export default {
  LOGIN: "/",
  NEWS_ITEM: (id) => `/dashboard/news/${id}`,
  CLONE_NEWS: (id) => `/dashboard/cloneNews/${id}`,
  PROFILE_ITEM: (id) => `/dashboard/profile/${id}`,
  PROFILES: "/dashboard/profiles",
  USER_ITEM: (id) => `/dashboard/users/${id}`,
  USERS: `/dashboard/users/`,
  ANNOUNCERS: `/dashboard/announcers`,
  ANNOUNCER_ITEM: (id) => `/dashboard/announcers/${id}`,
  NOTIFICATIONS: "/dashboard/notifications",
  POLL_ITEM: (id) => `/dashboard/poll/${id}`,
  POLLS: `/dashboard/polls`,
  FORECAST_ITEM: (id) => `/dashboard/weather/${id}`,
  FORECAST: `/dashboard/weather`,
  PROGRAM_ITEM: (id) => `/dashboard/programs/${id}`,
  PROGRAMS: `/dashboard/programs`,
  POSTS: `/dashboard/posts`,
  POST_ITEM: (id) => `/dashboard/posts/${id}`,
  MULTIMEDIA: "/dashboard/multimedia",
  MULTIMEDIA_ITEM: (id) => `/dashboard/multimedia/${id}`,
  SOURCE: "/dashboard/sources",
  SOURCE_ITEM: (id) => `/dashboard/sources/${id}`,
  WEATHER: `/dashbaord/clima`,
  LIVE_LINKS_ITEM: (id) => `/dashboard/live-links/${id}`,
  LIVE_LINKS: `/dashboard/live-links`  
};
