import React, { useReducer, useEffect, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import {
  Container,
  PageHeader,
  TextInput,
  Divider,
  Button,
  Column,
  Error,
  TagsInput,
  FileManager,
  ImageBackground,
} from "@ranch/components";
import { pollSchema, findPoll, createUpdatePoll } from "@ranch/models/polls";
import moment from "moment";
import ModalContext from "@ranch/contexts/ModalContext";
import { setModal, hideModal } from "@ranch/contexts/ModalContext/actions";
import { notify } from "react-notify-toast";
import { settings, routes } from "@ranch/config";

const PollDetailsScreen = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, {
    saving: false,
    pollImage: null,
  });

  const { saving, pollImage } = state;

  const { register, handleSubmit, errors, setValue, getValues, watch } =
    useForm({
      resolver: yupResolver(pollSchema),
    });

  watch("options");
  watch("starts_at");
  watch("ends_at");

  const onSubmit = async (data) => {
    setState({ saving: true });
    const submitType = id && id > 0 ? "update" : "create";

    const pollData = {
      ...data,
    };

    if (id && id > 0) {
      pollData.id = id;
    }

    const [error, status, { message }] = await createUpdatePoll(
      pollData,
      submitType
    );

    if (status === 200) {
      if (message === "UPDATED_POLL") {
        notify.show("Encuesta actualizada correctamente.", "success");
      }

      if (message === "CREATED_POLL") {
        notify.show("Encuesta creada correctamente.", "success");
        setTimeout(() => {
          history.push(routes.POLLS);
        }, 1500);
      }
    }

    if (status === 202) {
      if (message === "ERROR_UPDATING_POLL") {
        notify.show("Error al actualizar encuesta.", "error");
      }

      if (message === "ERROR_CREATING_POLL") {
        notify.show("Error al crear encuesta.", "error");
      }
    }

    setState({ saving: false });
  };

  const hasError = (field) => {
    if (errors[field]) {
      return errors[field].message;
    }
    return null;
  };

  const initialize = useCallback(async () => {
    register({ name: "options", value: [] });
    register({ name: "file_id", value: null });
    if (id && Number(id)) {
      const [error, status, data] = await findPoll(id);

      if (status === 200 && data) {
        setValue("title", data.title);
        setValue("starts_at", moment(data.starts_at).format("YYYY-MM-DD"));
        setValue("ends_at", moment(data.ends_at).format("YYYY-MM-DD"));
        setValue("file_id", data.file_id);
        setState({ pollImage: `${settings.imagesUrl}${data.image.small_url}` });

        const answers = [];
        data.options.forEach((item) => {
          answers.push(item.title);
        });
        setValue("options", answers);
      }
    }
  },[id, register, setValue]);

  const handleSelectedFile = (file) => {
    //id, type, url
    setValue("file_id", file.id);
    setState({ pollImage: file.url });
    hideModal(modalDispatch);
  };

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openFileManager = () =>
    setModal(modalDispatch, {
      title: "Administrador de archivos",
      children: <FileManager onFileSelected={handleSelectedFile} />,
      useButton: false,
      extraProps: {},
    });

  return (
    <Container color={theme.colors.grayLight}>
      <Container color={theme.colors.grayLight} padding={"50px"}>
        <Container padding={"15px"} justify={"space-between"}>
          <PageHeader>Detalles de la encuesta</PageHeader>
        </Container>
        <Column padding={"15px"}>
          <Container width={"40%"}>
            {pollImage && <ImageBackground src={pollImage} large />}
            <Divider size={10} />
            <Button onClick={() => openFileManager()}>
              Imagen de la encuesta
            </Button>
          </Container>
          <Divider size={20} />
          <TextInput
            name={"title"}
            label={"Título"}
            ref={register}
            type={"text"}
            error={hasError("title")}
          />
          <Divider size={20} />

          <Container>
            <Container row>
              <Container>
                <TextInput
                  name={"starts_at"}
                  label={"Fecha de inicio"}
                  ref={register}
                  type={"date"}
                  error={hasError("starts_at")}
                />
              </Container>
              <Divider />
              <Container>
                <TextInput
                  name={"ends_at"}
                  label={"Fecha de terminación"}
                  ref={register}
                  type={"date"}
                  error={hasError("ends_at")}
                />
              </Container>
            </Container>
          </Container>
          <Divider size={20} />
          <Container>
            <TagsInput
              label={"Respuestas"}
              labelStyle={{
                color: "#000",
                fontSize: 16,
                display: "block",
                marginBottom: 5,
              }}
              shouldSanitize={false}
              tags={getValues("options") || []}
              onChange={(tags) => setValue("options", tags)}
            />
            {hasError("options") && <Error>{hasError("options")}</Error>}
          </Container>
          <Divider size={20} />
          <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
            Guardar Encuesta
          </Button>
        </Column>
      </Container>
    </Container>
  );
};

export default PollDetailsScreen;
