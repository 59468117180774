import styled from 'styled-components';

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  flex: 1;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ selected, theme }) =>
    selected &&
    `
    border-bottom:3px solid ${theme.colors.primary};
`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray2};
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const ImageWrap = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 90px;
  background-size: cover;
  background-image: url('${({ src }) => src}');
  background-position: center;
`;

const ImagePreview = styled.div`
  border-radius: 8px;
  width: 300px;
  height: 300px;
  background-size: cover;
  background-image: url('${({ src }) => src}');
  background-position: center;
`;

export { Tabs, Tab, Wrapper, ImageWrap, ImagePreview };
